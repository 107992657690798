import React from "react";
import { Container, Row, Col, Form, Button, Card, Alert } from "react-bootstrap";
import Constants from "../../constant";
import Axios from "axios";
import { connect } from "react-redux";
import { loginUser } from "../../actions/actions";
import { Paper, Typography, Snackbar } from "@material-ui/core";
import { trackPromise } from "react-promise-tracker";



const mapStateToProps = state => {
  return { user: state.user };
};

function mapDispatchToProps(dispatch) {
  return {
    loginUser: userdata => dispatch(loginUser(userdata))
  };
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false
    }
    // this.login = this.login.bind(this);
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {

    } else {
      let email = this.emailRef.current.value;
      let password = this.passwordRef.current.value;
      trackPromise(Axios.post(Constants.BASE_URL_API + 'login', { email, password }).then((res) => {
        if (res.data.STATUS == "SUCCESS") {
          this.props.loginUser(res.data.DATA);
        } else {
          this.setState({ error: res.data.ERROR.MESSAGE });
          setTimeout(() => {
            this.setState({ error: "" });
          }, 6000);
        }
      }).catch((e, re) => {

        if (e.response.hasOwnProperty('data') && e.response.data.ERROR) {
          this.setState({ error: e.response.data.ERROR.MESSAGE });
          setTimeout(() => {
            this.setState({ error: "" });
          }, 6000);
        }else{
          this.setState({ error: 'Something went wrong!' });
          setTimeout(() => {
            this.setState({ error: "" });
          }, 6000);
        }

      }))
    }
    this.setState({ validated: true });

    event.preventDefault();
    event.stopPropagation();
    // return false;



  };
  render() {




    return (
      <div>


        <Container className="my-5">
          <Row className="my-3 text-left">
            <Col sm="12" md={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }}>
            <Snackbar
                            autoHideDuration={6000}
                            severity="error"
                            anchorOrigin={{vertical:'top',horizontal:'center'}}
                                open={this.state.error?true:false}
                                message={this.state.error}
                                onClose={()=>{
                                    this.setState({error:false});
                                }}
                            />
             <Paper className='p-3 'elevation={3}>
               <Typography variant='h3' align="center" className="mb-3">Login</Typography>
                  <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email Address Or Mobile Number</Form.Label>
                      <Form.Control className="login-field" name="email" required type="text" placeholder="Enter Email Address" ref={this.emailRef} />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid email address.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control className="login-field" name="password" minLength="6" required type="password" placeholder="Enter Password" ref={this.passwordRef} />
                      <Form.Control.Feedback type="invalid">
                        Please provide a password. password must be atleast 6 character long
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="success"  type="submit"  >
                      Submit
                    </Button>
                  </Form>
                  </Paper>
            </Col>
          </Row>
        </Container>
      </div >
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);


