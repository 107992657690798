import React from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import Listing from "../listing/Listing";
import Axios from "axios";
import Constants from "../../constant";
import Select from "react-select";
import chroma from "chroma-js";
import { connect } from "react-redux";

import "./Listings.css";
const mapStateToProps = state => {
  return {
    user: state.user,
    token: state.auth_token,
    logged_in: state.logged_in
  };
};

class MyListings extends React.Component {
  colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (isSelected ? data.color : color.alpha(0.3).css())
        }
      };
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      listings: [],
      categories: [],
      selectedCategory: {
        label: "All",
        value: "all",
        type: "category",
        color: "green"
      },
      page: 1,
      loadingMore: false,
      lastPage: 1
    };

    this.onChangeSubcategory = this.onChangeCategory.bind(this);
    this.getListings = this.getListings.bind(this);
    this.getMoreListings = this.getMoreListings.bind(this);
    this.moreListings = this.moreListings.bind(this);
  }

  onChangeCategory(category) {
    console.log(this);
    this.setState({ selectedCategory: category, page: 1 });
    this.getListings(category, 1);
  }

  getListings(category, page) {
    category.page = page;
    Axios.post(Constants.BASE_URL + "api/mylisting", category, {
      headers: { Authorization: "Bearer " + this.props.token }
    }).then(res => {
      this.setState({ listings: res.data.data, lastPage: res.data.last_page });
    });
  }
  getMoreListings(category, page) {
    page++;
    category.page = page;
    this.setState({ loadingMore: true, page: page });
    Axios.post(Constants.BASE_URL + "api/listings", category).then(res => {
      var listings = this.state.listings;
      if (listings.length > 0) {
        listings = listings.concat(res.data.data);
      } else {
        listings = res.data.data;
      }
      this.setState({ listings: listings, loadingMore: false });
    });
  }
  moreListings() {
    this.getMoreListings(this.state.selectedCategory, this.state.page);
  }
  componentDidMount() {
    var category_id = 1;
    Axios.get(Constants.BASE_URL + "api/categories").then(res => {
      var categories = [
        { label: "All", value: "all", type: "category", color: "green" }
      ];
      res.data.forEach(category => {
        var newcategory = {
          label: category.name,
          value: category.id,
          type: "category",
          color: "purple"
        };
        categories.push(newcategory);
        category.sub_category.forEach(subcategory => {
          categories.push({
            label: subcategory.name,
            value: subcategory.id,
            type: "subcategory",
            color: "blue"
          });
        });
      });
      this.setState({ categories });

      this.getListings(categories[0], this.state.page);
    });
    console.log(this.props.location);
  }

  render() {
    return (
      <div>
        <Container className="my-5">

          <Row>
            <Col sm="12">
              <h2 className="my-3 ">My Listings</h2>
              <Select
               
                placeholder="Select Category or Subcategory"
                options={this.state.categories}
                styles={this.colourStyles}
                onChange={category => {
                  this.onChangeCategory(category);
                }}
                defaultValue={this.state.categories[0]}
              ></Select>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <h2 className=" text-center">
                {this.state.selectedCategory.label}
              </h2>
            </Col>
            {this.state.listings.length > 0 ? (
              this.state.listings.map((value, index) => {
                return (
                  <Col sm="12" md="6" lg="4" key={"col" + index}>
                    <Listing key={index} data={value} from="mylisting" />
                  </Col>
                );
              })
            ) : (
              <Col sm={12}>
                <Card>
                  <Card.Body>No Listing Found</Card.Body>
                </Card>
              </Col>
            )}
          </Row>
          {this.state.page == this.state.lastPage ? (
            <div></div>
          ) : (
            <Row className="my-3 text-center">
              {!this.state.loadingMore ? (
                <Button
                  size="lg"
                  variant="light"
                  block
                  onClick={this.moreListings}
                >
                  More{" "}
                </Button>
              ) : (
                <div className="text-center w-100">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </Row>
          )}
          
        </Container>
      </div>
    );
  }
}
MyListings = connect(mapStateToProps)(MyListings);

export default MyListings;
