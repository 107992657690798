import React from "react";
import { Card ,Row,Col} from "react-bootstrap";
import Constants from "../../constant";
import {Link} from "react-router-dom";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Typography } from "@material-ui/core";
// import Category from "./categories/Category";
// import Axios from "axios";
class Listing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.render = this.render.bind(this);
    console.log(props);
  }

  render() {
    return (
      <div>
        <Link to={"/listingdetail/" + this.props.data.id}>
          <Card className="mt-3 text-left">
         
            
                <Card.Body style={{paddingBottom:"0"}}>
                <Row >
          <Col xs={4} className="p-1">{this.props.data.listing_images ? (
                
                <Card.Img
                  variant="top"
                  className="img-fluid"
                  style={{height:"80px"}}
                  src={
                    Constants.STORAGE_URL +
                    this.props.data.listing_images[0].image
                  }
                />
            
            ) : (
              <div></div>
            )}</Col>
            <Col xs={8}>
              <Typography variant="subtitle1" style={{fontWeight:"bold"}} >
                   {this.props.data.listing_name}   
              </Typography>
              
                
                  <Typography>
                      <i className="material-icons">account_circle</i> &nbsp;{" "}
                     <div style={{marginTop:"-31px",marginLeft:"30px"}}>{this.props.data.listing_contact_name}</div> 
                    </Typography>
                    <Typography>
                      <i className="material-icons">call</i> &nbsp;{" "}
                     <div style={{marginTop:"-31px",marginLeft:"30px"}}>{this.props.data.listing_phone}</div> 
                    </Typography>
                    {this.props.data.listing_email?<Typography>
                      <i className="material-icons">mail</i> &nbsp;{" "}
                     <div style={{marginTop:"-31px",marginLeft:"30px"}}>{this.props.data.listing_email}</div> 
                    </Typography>:<span></span>}
                    
                    {/* <Typography>
                      <i className="material-icons">reorder</i> &nbsp;{" "}
                      <div style={{marginTop:"-31px",marginLeft:"30px"}}>
                      {this.props.data.listing_description.length>70?this.props.data.listing_description.substring(0,69)+'...' :this.props.data.listing_description}

                      </div>
                    </Typography> */}
                  <ul>
                  {/* <li>
                      <i className="material-icons">account_circle</i> &nbsp;{" "}
                      {this.props.data.listing_contact_name}
                    </li>
                    <li>
                      <i className="material-icons">reorder</i> &nbsp;{" "}
                      {this.props.data.listing_description.length>25?this.props.data.listing_description.substring(0,24)+'...' :this.props.data.listing_description}
                    </li> */}
                    {/* <li>
                      <i className="material-icons">phone</i> &nbsp;{" "}
                      {this.props.data.listing_phone}
                    </li>
                    {this.props.data.listing_whatsappphone ? (
                       <li>
                       <WhatsAppIcon></WhatsAppIcon> &nbsp;{" "}
                       {this.props.data.listing_whatsappphone}
                     </li>
                    ) : (
                      <li></li>
                    )}
                  
                    <li>
                      <i className="material-icons">my_location</i> &nbsp;{" "}
                      {this.props.data.listing_address +
                        ", " +
                        this.props.data.listing_pincode}
                    </li>
                    
                    {this.props.data.listing_email ? (
                      <li>
                        <i className="material-icons">contact_mail</i> &nbsp;{" "}
                        {this.props.data.listing_email}
                      </li>
                    ) : (
                      <li></li>
                    )}
                    {this.props.data.listing_website ? (
                      <li>
                        <i className="material-icons">web</i> &nbsp;{" "}
                        {this.props.data.listing_website}
                      </li>
                    ) : (
                      <li></li>
                    )}
                    {this.props.from == "mylisting" ? (
                      <li>
                        <i className="material-icons">assignment_turned_in</i>{" "}
                        {this.props.data.status}
                      </li>
                    ) : (
                      <li></li>
                    )} */}
                  </ul>
             </Col>

          </Row>
              </Card.Body>
           
          </Card>
        </Link>
      </div>
    );
  }
}
export default Listing;
