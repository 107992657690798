import React from "react";
import Axios from "axios";
import Constants from "../../../constant";
import { Carousel } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Card, CardMedia } from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img from "react-image";
class HomeCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = { offers: [] };

   this.settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight:false,
      // variableWidth: true,
      arrows:false,
      autoplay:true,centerMode: false,
    };
    trackPromise(Axios.get(Constants.BASE_URL + "api/carousel").then(res => {
      this.setState({ offers: res.data });
    }));
  }
  
  render() {



    return (
      <div>
        <Slider {...this.settings}>
          {this.state.offers.map(offer => (
            <Link to={offer.link} key={offer.link}>
            <div className='mx-2' >
          <Img
          style={{width:'100%',borderRadius:'20px'}}
                  src={Constants.STORAGE_URL+offer.image}
                  // title="Contemplative Reptile"
                />
            
            </div>
            </Link>
            ))}
   </Slider> 
  </div>
  )
}}
export default HomeCarousel;
