import React from "react";
import {
  Card,
  Container,
  Col,
  Row,
  Form,
  Button,
  Alert
} from "react-bootstrap";
import Constants from "../../constant";

// import Category from "./categories/Category";
import Adstwo from "../Homepage/carousel/Carouseltwo";
import Adsthree from "../Homepage/carousel/Carouselone";
import { useParams } from "react-router-dom";
import Axios from "axios";
import ProductCarousel from "./Carousel";
import { trackPromise } from "react-promise-tracker";
import { Paper, Typography, Snackbar } from "@material-ui/core";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { connect } from "react-redux";
const mapStateToProps = state => {
  return {
    user: state.user,
    token: state.auth_token,
    logged_in: state.logged_in
  };
};
class ListingDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      enquiries: [],
      name: "",
      comment: "",
      email: "",
      mobile: "",
      enquiryname: "",
      enquirymessage: "",
      enquiryaddress: "",
      enquirymobile: "",
      enquiryAlertMessage: '',
      alertMessage: ''
    };
    // let { id } = useParams();
    console.log(this.props.match.params.id);
    this.render = this.render.bind(this);
    trackPromise(Axios.post(Constants.BASE_URL + "api/listing", {
      listing_id: this.props.match.params.id
    }).then(res => {
      this.setState({ listing: res.data });
    }));
    this.comments = this.comments.bind(this);
    this.render = this.render.bind(this);

    this.comments();
    this.enquiry();
  }
  enquiry = () => {
    trackPromise(Axios.post(Constants.BASE_URL + "api/getenquiry", {
      listid: this.props.match.params.id
    }, {
      headers: { Authorization: "Bearer " + this.props.token }
    }).then(res => {
      this.setState({ enquiries: res.data });
    }));
  };
  comments = () => {
    trackPromise(Axios.post(Constants.BASE_URL + "api/getcomment", {
      listid: this.props.match.params.id
    }).then(res => {
      this.setState({ comments: res.data });
    }));
  };
  dataChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  submitEnquiry = e => {
    e.preventDefault();
    var data = {
      name: this.state.enquiryname,
      message: this.state.enquirymessage,
      address: this.state.enquiryaddress,
      mobileno: this.state.enquirymobile,
      listid: this.props.match.params.id
    };

    trackPromise(Axios.post(Constants.BASE_URL + "api/listenquiry", data)
      .then(res => {
        if (res.data.success == 1) {
          this.setState({
            enquiryname: "",
            enquiryaddress: "",
            enquirymessage: "",
            enquirymobile: ""
          });
          this.comments();
          this.setState({
            enquiryAlertMessage: res.data.message,
            alertType: "success"
          });
        } else if (res.data.success == 0) {
          this.setState({
            enquiryAlertMessage: res.data.message,
            alertType: "danger"
          });
        } else {
          this.setState({
            enquiryAlertMessage: "Something went wrong",
            alertType: "danger"
          });
        }
      })
      .catch(e => {
        console.log(e.response);
        
        if (e.response.hasOwnProperty('data')&& e.response.data.hasOwnProperty('STATUS')&&e.response.data.STATUS == "FAIL") {
          this.setState({
            enquiryAlertMessage: e.response.data.ERROR.MESSAGE[0],
            alertType: "danger"
          });
        }else{
          this.setState({
            enquiryAlertMessage: "Something went wrong",
            alertType: "danger"
          });
        }
       
      }));
  };

  Submithandler = e => {
    e.preventDefault();
    var data = {
      name: this.state.name,
      comment: this.state.comment,
      email: this.state.email,
      mobileno: this.state.mobile,
      listid: this.props.match.params.id
    };

    trackPromise(Axios.post(Constants.BASE_URL + "api/comments", data)
      .then(res => {
        if (res.data.success == 1) {
          this.setState({ comment: "", email: "", mobile: "", name: "" });
          this.comments();
          this.setState({
            alertMessage: res.data.message,
            alertType: "success"
          });
        } else if (res.data.success == 0) {
          this.setState({
            alertMessage: res.data.message,
            alertType: "danger"
          });
        } else {
          this.setState({
            alertMessage: "Something went wrong",
            alertType: "danger"
          });
        }
      })
      .catch(e => {
        this.setState({
          alertMessage: "Something went wrong",
          alertType: "danger"
        });
      }));
  };
  render() {
    // const divStyle = {
    //   borderRadius: "22px"
    // };
    const { comment, email, mobile, name } = this.state;
    return (
      <div>
       
        <Container>
        <Row className="my-3">
          <Col sm="12">
            <Adstwo />
          </Col>
        </Row>
          <Card className="mt-3 text-left">
            {this.state.listing ? (
              <Row>
                <Col sm={12} md={7}>
                  <ProductCarousel
                    images={this.state.listing.listing_images}
                  ></ProductCarousel>
                </Col>
                <Col sm={12} md={5}>
                  <Card.Body>
                    <Card.Title>
                      <h3>
                        <i className="material-icons md-24"> store </i>
                        {this.state.listing.listing_name}
                      </h3>{" "}
                    </Card.Title>
                    <Row className="text-center">
                      <Col >
                      <a href={"tel:"+this.state.listing.listing_phone} ><i style={{fontSize:"50px"}} className="material-icons">phone</i></a>
                      </Col>
                     {this.state.listing.listing_whatsappphone? <Col >
                    <a href={"https://api.whatsapp.com/send?phone="+this.state.listing.listing_whatsappphone+"&text=Hii"} > <WhatsAppIcon style={{fontSize:"50px"}}></WhatsAppIcon></a>
                      </Col>:<span></span>}
                      {this.state.listing.listing_email?<Col >
                      <a href={"mailto:"+this.state.listing.listing_email}> <i className="material-icons" style={{fontSize:"50px"}} >contact_mail</i></a>
                      </Col>:<span></span>}
                     {this.state.listing.listing_website? <Col><a href={this.state.listing.listing_website} target="_blank" rel="noopener noreferrer"><i style={{fontSize:"50px"}} className="material-icons">language</i></a></Col>:<span></span>}
                     {/* { window.navigator.share? <Col><a onClick={
                         window.navigator.share(this.state.listing.listing_name+"\n Contact Person: "+this.state.listing.listing_contact_name+"\n"+this.state.listing.listing_description+"\n Phone:"+this.state.listing.listing_phone+"\n WhatsApp:"+this.state.listing.listing_whatsappphone +"\n "+this.state.listing.listing_address +
                        ", " +
                        this.state.listing.listing_pincode+"\n Email:"+this.state.listing.listing_email+"\n  Website:"+this.state.listing.listing_website)
                      } > <i style={{fontSize:"50px"}} className="material-icons">share</i></a></Col>:<span></span>} */}
                    </Row>
                    <ul>
                      <li>
                        <i className="material-icons">account_circle</i> &nbsp;{" "}
                        {this.state.listing.listing_contact_name}
                      </li>
                      <li>
                        <i className="material-icons">reorder</i> &nbsp;{" "}
                        {this.state.listing.listing_description}
                      </li>
                      <li>
                        <i className="material-icons">phone</i> &nbsp;{" "}
                        {this.state.listing.listing_phone}
                      </li>
                      {this.state.listing.listing_whatsappphone ? (
                       <li>
                       <WhatsAppIcon></WhatsAppIcon> &nbsp;{" "}
                       {this.state.listing.listing_whatsappphone}
                     </li>
                    ) : (
                      <li></li>
                    )}
                      <li>
                        <i className="material-icons">my_location</i> &nbsp;{" "}
                        {this.state.listing.listing_address +
                          ", " +
                          this.state.listing.listing_pincode}
                      </li>
                      {this.state.listing.listing_email ? (
                        <li>
                          <i className="material-icons">contact_mail</i> &nbsp;{" "}
                          {this.state.listing.listing_email}
                        </li>
                      ) : (
                          <li></li>
                        )}
                      {this.state.listing.listing_website ? (
                        <li>
                          <i className="material-icons">web</i> &nbsp;{" "}
                          {this.state.listing.listing_website}
                        </li>
                      ) : (
                          <li></li>
                        )}
                    </ul>
                  </Card.Body>
                </Col>
              </Row>
            ) : (
                <div></div>
              )}
          </Card>
        </Container>
        <Container>
          <Row className="mt-3">
          <Snackbar
                autoHideDuration={6000}
                severity="error"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={this.state.enquiryAlertMessage ? true : false}
                message={typeof (this.state.enquiryAlertMessage) !== 'string' ? this.state.enquiryAlertMessage[0] : this.state.enquiryAlertMessage}
                onClose={() => {
                  this.setState({ enquiryAlertMessage: false });
                }}
              />
            {/* <Col sm={12} md={6} className="mt-3">
             
              <Snackbar
                autoHideDuration={6000}
                severity="error"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={this.state.alertMessage ? true : false}
                message={typeof (this.state.alertMessage) !== 'string' ? this.state.alertMessage[0] : this.state.alertMessage}
                onClose={() => {
                  this.setState({ alertMessage: false });
                }}
              />
              <Paper className='p-3 '>
                <Typography variant='h4' align="center" className="mb-3">Reviews</Typography>
                <Row className="text-left">
                  {this.state.comments.length > 0 ? (
                    this.state.comments.map(commentdata => (
                      <Col sm={12} className="mt-1">
                        <h5>{commentdata.email}</h5>
                        <p className="m-1">{commentdata.comment}</p>
                        <small className="w-100 text-right d-block">
                          {commentdata.updated_at}
                        </small>
                        <hr></hr>
                      </Col>
                    ))
                  ) : (
                      <div></div>
                    )}
                </Row>
                <Row>
                  <Col sm={12}>
                   
                    <Form onSubmit={this.Submithandler}>
                      <Row className="mt-2">
                        <Col sm={12}>
                          <Form.Control
                            className="input"
                            type="text"
                            value={this.state.name}
                            onChange={this.dataChange}
                            name="name"
                            placeholder="Enter Name"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={12}>
                          <Form.Control
                            className="input"
                            type="text"
                            value={this.state.comment}
                            onChange={this.dataChange}
                            name="comment"
                            placeholder="Comment Here"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={12}>
                          <Form.Control
                            className="input"
                            type="number"
                            value={this.state.mobile}
                            onChange={this.dataChange}
                            name="mobile"
                            placeholder="Enter Mobileno"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={12}>
                          <Form.Control
                            className="input"
                            type="email"
                            value={this.state.email}
                            onChange={this.dataChange}
                            name="email"
                            placeholder="enter Email"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={12}>
                          <Button variant="success" type="submit" block>
                            Comment
                            </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Paper>
            
            </Col> */}

            <Col sm={12}  className="mt-3">
            <Paper className='p-3 '>
                <Typography variant='h4' align="center" className="mb-3">Enquiry</Typography>
           
                  <Row className="text-left">
                    {this.state.enquiries.length > 0 ? (
                      this.state.enquiries.map(commentdata => (
                        <Col sm={12} className="mt-1">
                          <h5>{commentdata.name}</h5>
                          <p className="m-1">{commentdata.message}</p>
                          <p className="m-1">{commentdata.mobileno}</p>
                          <p className="m-1">{commentdata.email}</p>

                          <small className="w-100 text-right d-block">

                            {commentdata.updated_at}
                          </small>
                          <hr></hr>
                        </Col>
                      ))
                    ) : (
                        <div></div>
                      )}
                  </Row>
                  <Row>
                    <Col sm={12}>
                      {/* {this.state.enquiryAlertMessage != "" ? (
                        <Alert className="w-100" variant={this.state.alertType}>
                          {this.state.enquiryAlertMessage}
                        </Alert>
                      ) : (
                        <div></div>
                      )} */}
                      <Form onSubmit={this.submitEnquiry}>
                        <Row className="mt-2">
                          <Col sm={12}>
                            <Form.Control
                              required={true}
                              className="input"
                              type="text"
                              value={this.state.enquiryname}
                              onChange={this.dataChange}
                              name="enquiryname"
                              placeholder="Enter Name"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={12}>
                            <Form.Control
                              required={true}
                              className="input"
                              type="text"
                              value={this.state.enquirymessage}
                              onChange={this.dataChange}
                              name="enquirymessage"
                              placeholder="Enter Message"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={12}>
                            <Form.Control
                              required={true}
                              className="input"
                              type="number"
                              value={this.state.enquirymobile}
                              onChange={this.dataChange}
                              name="enquirymobile"
                              placeholder="Enter Mobile No."
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={12}>
                            <Form.Control
                              className="input"
                              required={true}
                              type="text"
                              value={this.state.address}
                              onChange={this.dataChange}
                              name="enquiryaddress"
                              placeholder="Enter Address"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm={12}>
                            <Button variant="success" type="submit" block>
                              Comment
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
               </Paper>
            </Col>
          </Row>
          <Row className="my-3">
          <Col sm="12">
            <Adsthree />
          </Col>
        </Row>
        </Container>
       
      </div>
    );
  }
}
export default connect(mapStateToProps)(ListingDetail);
