import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
} from "react-bootstrap";
import Axios from "axios";
import { getCurrentDate } from "../../../node_modules/util";
import Constants from "../../constant";
import { trackPromise } from "react-promise-tracker";

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    notifications: [],
      page: 1,
      loadingMore: false,
      show: false,
      lastPage: 1
    };
    trackPromise( Axios.get(Constants.BASE_URL + "api/notification").then(res => {
      this.setState({ notifications: res.data.data, lastPage: res.data.last_page });
    }));
    this.moreNotifications = this.moreNotifications.bind(this);
    this.getMoreNotifications = this.getMoreNotifications.bind(this);
   
  }
 
 
  
  getMoreNotifications(page) {
    page++;
    console.log(page);
    this.setState({ loadingMore: true, page: page });
    trackPromise(Axios.get(Constants.BASE_URL + "api/notification?page=" + page).then(res => {
      var notifications = this.state.notifications;
      if (notifications.length > 0) {
        notifications = notifications.concat(res.data.data);
      } else {
        notifications = res.data.data;
      }
      this.setState({ notifications: notifications, loadingMore: false });
    }));
  }
  moreNotifications() {
    this.getMoreNotifications(this.state.page);
  }
  render() {
   
    return (
      <div>
        <Container className="mt-5">
          <Row>
            <Col sm="12">
             
              <h3 className="black-text">Notifications</h3>
             
            </Col>
          </Row>
          <Row className="text-left">
            {this.state.notifications.map(notification => (
              <Col sm={12} img-fluid>
                <a href={notification.link}>
                  <Card className="mt-3 p-0">
                   
                    <Card.Body>
                    <Card.Title>
                    {notification.heading}
                    </Card.Title>
                      <Card.Text>{notification.desc}</Card.Text>
                    
                       
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>
          {this.state.page == this.state.lastPage ? (
            <div></div>
          ) : (
            <Row className="my-3 text-center">
              {!this.state.loadingMore ? (
                <Button
                  size="lg"
                  variant="light"
                  block
                  onClick={this.moreNotifications}
                >
                  More{" "}
                </Button>
              ) : (
                <div className="text-center w-100">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </Row>
          )}
        </Container>
      </div>
    );
  }
}
export default Notifications;
