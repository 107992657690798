import React from 'react';
import { connect } from "react-redux";
import { Redirect  } from "react-router-dom";

const mapStateToProps = state => {
    console.log(state)
    return { logged_in: state.logged_in };
};

var IsLoggedIn = (props) => {
    if(props.logged_in===true){
        return <div>{props.children}</div>
    }else{
        return <Redirect to='/signup' />
    }
}
 IsLoggedIn = connect(mapStateToProps)(IsLoggedIn);

export default IsLoggedIn;