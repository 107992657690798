import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";

import Axios from "axios";
import Constants from "../../constant";
import "./contactus.css";
import Adsone from "./carousel/Carouselthree";
import Adstwo from "./carousel/Carouseltwo";
import { trackPromise } from "react-promise-tracker";
class Aboutus extends React.Component {
  constructor(props) {
    super(props);
    this.state = { info: [] };
    trackPromise( Axios.get(Constants.BASE_URL + "api/aboutus").then(res => {
      this.setState({ info: res.data });
    }));
  }
  render() {
    const divStyle = {
      marginTop:"30px",
      marginBottom:"50px",
     color:"black",
      borderRadius:"20px",
      fontSize:"20px",
    };
    return (
      <Container className="mt-5 black-text">
        <Row style={{paddingTop:"20px"}}>
          <Col sm={12} className="mb-1">
            <Adstwo/>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm={12}>
            <Row>
              <Col sm={12}>
                <h1 ><span id="datahead">About</span> Us</h1>
              </Col>
            </Row>
            <Row>
              <Col sm={12} style={divStyle}>
                <label><i>{this.state.info.map(info => info.about)}</i></label>
              </Col>
            </Row>
            
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="mt-1">
            <Adsone/>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Aboutus;
