import React from "react";
import { Footer, Container, Row, Col } from "react-bootstrap";
import "./footer.css";
import Axios from "axios";
import Constants from "../../constant";
import logo from "./logo.gif";
import google from "./googleplaystore.png";
import { trackPromise } from "react-promise-tracker";
class footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { info: [] };
    trackPromise(Axios.get(Constants.BASE_URL + "api/contactus").then(res => {
      this.setState({ info: res.data });
    }));
  }
  render() {
    return (
      <footer>
        <div id="Footer" className="text-left mt-3">
          <Container fluid>
            <Row className="mt-3">
              <Col className="text-center" sm={12} md={4} lg={4}>
                {/* <h3 id="footheading">
                <span style={{color:"	#CE4364"}}>Esmart</span><span style={{color:"#3AA2eb"}}>Services</span> 
              </h3> */}
                <img className="img-fluid w-50" src={logo} />
              </Col>
              <Col sm={12} md={4} lg={4} className=" text-left">
                <div className="vrone"></div>
                <span>Important Links</span>
                <br></br>
                <i class="material-icons" id="footicon2">
                  keyboard_arrow_right
                </i>
                <a href="/" id="linkfoot">
                  Home
                </a>
                <br />
                <i class="material-icons" id="footicon2">
                  keyboard_arrow_right
                </i>
                <a href="/jobopening" id="linkfoot">
                  Jobs
                </a>
                <br />
                <i class="material-icons" id="footicon2">
                  keyboard_arrow_right
                </i>
                <a href="/aboutus" id="linkfoot">
                  About Us
                </a>
                <br />
                <i class="material-icons" id="footicon2">
                  keyboard_arrow_right
                </i>
                <a href="/Contactus" id="linkfoot">
                  Contact Us
                </a>
                <br />

                <i class="material-icons" id="footicon2">
                  keyboard_arrow_right
                </i>
                <a href="/businessdirectory" id="linkfoot">
                  Business Directory
                </a>
                <br />
              </Col>

              <Col sm={12} md={4} lg={4}>
                <div id="dataadd">
                  <div className="vrone"></div>
                  <span>Contacts: </span>
                  <br />
                  <span id="addressfoot">
                    <i class="material-icons" id="footicon">
                      room
                    </i>
                    {this.state.info.map(
                      data =>
                        data.address + " " + data.city + " " + data.state + " "
                    )}
                  </span>
                  <br />
                  <span id="addressfoot">
                    <i class="material-icons" id="footicon">
                      {" "}
                      phone_in_talk
                    </i>
                    {this.state.info.map(
                      data => "+91" + data.mobileno + "  +91" + data.mbileno2
                    )}
                  </span>
                  <br />
                  <span id="addressfoot">
                    <i class="material-icons" id="footicon">
                      {" "}
                      email
                    </i>
                    {this.state.info.map(data => data.user.email)}
                  </span>
                  <div id="addressfoot">
                    <a href="#">
                      <img
                        className="img-fluid"
                        style={{  height: "100px" }}
                        src={google}
                      />
                    </a>
                  </div>
                </div>
              </Col>
              {/* <Col sm={12} md={3} lg={3}>
              <img className="img-fluid w-50" src={google} />
              </Col> */}
              {/* <Col sm={4}>
                    <br/>
                    <br/><br/><br/><br/>
                    <span id="addressfoot">©2019 All Right Reserved.</span>
                  </Col>
                </Row>
              </Col> */}
            </Row>
            <Row>
              <Col className="text-center mt-2" sm={12}>
                <span id="footheading">
                  <span style={{ color: "	#CE4364" }}>Esmart</span>
                  <span style={{ color: "#3AA2eb", paddingRight: "10px" }}>
                    Services
                  </span>
                  <span style={{ color: "#3AA2eb" }}>
                    &#169; 2020|Designed By{" "}
                    <a target="_blank" href="http://gsdsquare.com">
                      GSD Square Solutions
                    </a>
                  </span>
                </span>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    );
  }
}
export default footer;
