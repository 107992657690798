import React from "react";
import { Card ,Row,Col} from "react-bootstrap";
import Constants from "../../constant";
import { Redirect, Link } from "react-router-dom";
// import Category from "./categories/Category";
// import Axios from "axios";
class Listing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {goto:false};
    this.render = this.render.bind(this);
    console.log(props);
    
  }
 

  render() {
    
    return (
      <div>
          {this.state.goto!==false? <Redirect to={this.state.goto}></Redirect>:<div></div>}
         <Link to={"/listingdetail/" + this.props.data.id} >
          <Card className="mt-3 text-left" style={{height:'180px'}}>
          
              {this.props.data.listing_images ? (
                
                  <Card.Img
                    variant="top"
                    className="img-fluid"
                  style={{height:'120px'}}
                    src={
                      Constants.STORAGE_URL +
                      this.props.data.listing_images[0].image
                    }
                  />
              
              ) : (
                <div></div>
              )}
            
                <Card.Body className='p-0'>
                  <Card.Text>
                    <i className="material-icons md-24"> store </i>
                    {this.props.data.listing_name.length>20?this.props.data.listing_name.substring(0, 19)+'...':this.props.data.listing_name}
                  </Card.Text>
                       </Card.Body>
           
          </Card>
          </Link>
      </div>
    );
  }
}
export default Listing;
