import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Product from "./product/product";
import Axios from "axios";
import Constants from "../../constant";
import Select from "react-select";
import chroma from 'chroma-js';
import './Products.css'
class Products extends React.Component {

    colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
                },
            };
        },

    };

    constructor(props) {
        super(props);
        this.state = { products: [], categories: [], selectedCategory: { label: "All", value: "all", type: "category", color: "green" }, page: 1, loadingMore: false,lastPage:1 };

        this.onChangeSubcategory = this.onChangeCategory.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.getMoreProducts = this.getMoreProducts.bind(this);
        this.moreProducts = this.moreProducts.bind(this);
        }

    onChangeCategory(category) {
        console.log(this);
        this.setState({ selectedCategory: category, page: 1 })
        this.getProducts(category, 1);

    }

    getProducts(category, page) {
        category.page = page;
        Axios.post(Constants.BASE_URL + "api/products", category).then(res => {
            this.setState({ products: res.data.data ,lastPage:res.data.last_page});
        });
    }
    getMoreProducts(category, page) {
        page++;
        category.page = page;
        this.setState({ loadingMore: true ,page:page})
        Axios.post(Constants.BASE_URL + "api/products", category).then(res => {
            var products = this.state.products;
          if(products.length>0){
            products= products.concat(res.data.data)
          }else{
              products=res.data.data
          }            
            this.setState({ products: products, loadingMore: false });
        });
    }
    moreProducts(){
    this.getMoreProducts(this.state.selectedCategory,this.state.page);
    }
    componentDidMount() {
        var category_id = 1;
        Axios.get(Constants.BASE_URL + "api/categories").then(res => {

            var categories = [{ label: "All", value: "all", type: "category", color: "green" }];
            res.data.forEach((category) => {
                var newcategory = { label: category.name, value: category.id, type: "category", color: "purple" }
                categories.push(newcategory);
                category.sub_category.forEach((subcategory) => {
                    categories.push({ label: subcategory.name, value: subcategory.id, type: "subcategory", color: "blue" });
                })

            })
            this.setState({ categories });

            this.getProducts(categories[0], this.state.page);
        });
        console.log(this.props.location);
    }

    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col sm="12">
                            <h3 className="my-3">Business Directory</h3>
                            <Select
                                placeholder="Select Category or Subcategory"
                                options={this.state.categories}
                                styles={this.colourStyles}
                                onChange={(category) => {
                                    this.onChangeCategory(category)
                                }}
                                defaultValue={this.state.categories[0]}
                            ></Select>
                            {/* <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Stats select</Form.Label>
                                <Form.Control as="select" value={this.state.subcatid} onChange={this.onChangeSubcategory}>
                                    <option value="all">All</option>
                                    {this.state.subCategories.map((value, index) => {
                                        return <option key={index} value={value.id}>{value.name}</option>
                                    })}
                                </Form.Control> */}
                            {/* </Form.Group> */}
                        </Col>
                    </Row>
                    <Row>
                        {this.state.products.length>0?
                            this.state.products.map((value, index) => {
                            return <Col sm="3" key={index}><Product key={index} data={value} /> </Col>;
                        }):<div></div>}


                    </Row>
                    {
                        this.state.page==this.state.lastPage?
                        (<div></div>)
                        :(   <Row className="my-3 text-center">
                        {!this.state.loadingMore ?
                            (<Button size="lg" variant="light" block onClick={this.moreProducts}>More </Button>) :
                            (<div className="text-center w-100">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>)
                        }
                    </Row>)
                    }
                 
                </Container>
            </div >
        );
    }
}
export default Products;
