import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Modal,
  Form
} from "react-bootstrap";
import Adsone from "../carousel/Carouselone";
import Adstwo from "../carousel/Carouseltwo";
import Axios from "axios";
import Constants from "../../../constant";
import "./jobs.css";
import { trackPromise } from "react-promise-tracker";

class Jobopening extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Listjobs: [],
      show: false,
      showjob: false,
      job: {},
      page: 1,
      lastPage: 1,
      name: "",
      number: "",
      uresume: null,
      jobid: ""
    };
    trackPromise(   Axios.get(Constants.BASE_URL + "api/jobs").then(res => {
      this.setState({ Listjobs: res.data.data, lastPage: res.data.last_page });
    }));

    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClosejob = this.handleClosejob.bind(this);
    this.handleShowjob = this.handleShowjob.bind(this);
    this.getMoreJobs = this.getMoreJobs.bind(this);
    this.moreJobs = this.moreJobs.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }
  handleShow(jobs) {
    this.setState({ show: true, job: jobs });
  }
  handleClosejob() {
    this.setState({ showjob: false });
  }
  handleShowjob(jobs) {
    this.setState({ showjob: true, job: jobs, jobid: jobs.id });
    console.log(jobs);
  }
  getMoreJobs(page) {
    page++;
    console.log(page);
    this.setState({ loadingMore: true, page: page });
    trackPromise( Axios.get(Constants.BASE_URL + "api/jobs?page=" + page).then(res => {
      var Listjobs = this.state.Listjobs;
      if (Listjobs.length > 0) {
        Listjobs = Listjobs.concat(res.data.data);
      } else {
        Listjobs = res.data.data;
      }
      this.setState({ Listjobs: Listjobs, loadingMore: false });
    }));
  }
  moreJobs() {
    this.getMoreJobs(this.state.page);
  }
  dataChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  Submithandler = e => {
    e.preventDefault();
   
    var bodyFormData = new FormData();

    bodyFormData.set("name", this.state.name);
    bodyFormData.set("number", this.state.number);
    bodyFormData.set("jobid", this.state.jobid);
    bodyFormData.append("uresume", this.ResumeRef.files[0]);



    trackPromise( Axios.post(Constants.BASE_URL + "api/applyjobs", bodyFormData, { headers: { 'Content-Type': 'multipart/form-data'}})
      .then(res => {
        if (res.data.success == 1) {
          this.setState({ name: "", number: "", uresume: "" });
          this.setState({
            alertMessage: res.data.message,
            alertType: "success"
          });
        } else if (res.data.success == 0) {
          this.setState({
            alertMessage: res.data.message,
            alertType: "danger"
          });
        } else {
          this.setState({
            alertMessage: "Something went wrong",
            alertType: "danger"
          });
        }
      })
      .catch(e => {
        this.setState({
          alertMessage: "Something went wrong",
          alertType: "danger"
        });
      }));
  };
  render() {
    const { name, number, uresume } = this.state;
    var id = "";
    return (
      <Container className="jobs">
        
        <Row className="">
          <Col sm="12">
            
            <h1 ><span id="datahead">Jobs</span> Vacancy</h1>
           
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Row>
              {this.state.Listjobs.map(jobs => (
                <Col sm="12" md={6} lg={4} className="mt-1">
                  <Card className="bg-dark text-white">
                    <Card.Header>Vacancy</Card.Header>
                    <Card.Body>
                      <Card.Title>{jobs.name}</Card.Title>
                      <Card.Text>
                        <Row className="cardjob">
                          <Col sm="6">
                            <label>Salary</label>
                          </Col>
                          <Col sm="6">
                            <label>Rs{jobs.salary}</label>
                          </Col>
                        </Row>
                        <Row className="cardjob">
                          <Col sm="6">
                            <label>Qualification</label>
                          </Col>
                          <Col sm="6">
                            <label>{jobs.quali}</label>
                          </Col>
                        </Row>
                        <Row className="cardjob">
                          <Col sm="6">
                            <label>Minimum Exprience</label>
                          </Col>
                          <Col sm="6">
                            <label>{jobs.jobexpri}</label>
                          </Col>
                        </Row>
                        <Row className="cardjob">
                          <Col sm="6">
                            <label>Lastdate</label>
                          </Col>
                          <Col sm="6">
                            <label>{jobs.lastdate}</label>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="6" className="mt-2">
                            <Button
                              variant="info"
                              block
                              onClick={() => this.handleShow(jobs)}
                            >
                              View More
                            </Button>
                          </Col>
                          <Col sm="6" className="mt-2">
                            <Button
                              variant="success"
                              block
                              onClick={() => this.handleShowjob(jobs)}
                            >
                              Apply Now
                            </Button>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        {this.state.page == this.state.lastPage ? (
          <div></div>
        ) : (
          <Row className="my-3 text-center">
            {!this.state.loadingMore ? (
              <Button size="lg" variant="light" block onClick={this.moreJobs}>
                More{" "}
              </Button>
            ) : (
              <div className="text-center w-100">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </Row>
        )}
        
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.job.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col sm={6}>Owner Name:</Col>
                <Col sm={6}>
                  <label>
                    {this.state.job ? this.state.job.ownername : <div></div>}
                  </label>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>Owner Email:</Col>
                <Col sm={6}>
                  {this.state.job ? this.state.job.email : <div></div>}
                </Col>
              </Row>
              <Row>
                <Col sm={6}>Owner MobileNo:</Col>
                <Col sm={6}>
                  {this.state.job ? this.state.job.mobileno : <div></div>}
                </Col>
              </Row>
              {/* <Row>
                <Col sm={6}>Owner MobileNo 2:</Col>
                <Col sm={6}>
                  {this.state.job.contactus ? (
                    this.state.job.contactus.mbileno2
                  ) : (
                    <div></div>
                  )}
                </Col>
              </Row> */}
              <Row>
                <Col sm={12} className="text-center">
                  visit us at:
                </Col>
              </Row>
              <Row>
                <Col
                  sm={12}
                  className="text-center"
                  className="text-center text-success"
                >
                  {this.state.job ? (
                    <address>{this.state.job.address}</address>
                  ) : (
                    <div></div>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showjob} onHide={this.handleClosejob}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.job.name}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.Submithandler}>
            <Modal.Body>
              <Row id="formrow">
                <Col sm={12}>
                  <Form.Label>
                    <div className="vr" />
                    Name
                  </Form.Label>
                  <Form.Control
                    className="input"
                    type="text"
                    value={this.state.name}
                    onChange={this.dataChange}
                    name="name"
                    placeholder="Enter Name"
                    required
                  />
                </Col>
              </Row>
              <Row id="formrow">
                <Col sm={12}>
                  <Form.Label>
                    <div className="vr" />
                    Mobile Number
                  </Form.Label>
                  <Form.Control
                    className="input"
                    type="number"
                    value={this.state.number}
                    onChange={this.dataChange}
                    name="number"
                    placeholder="Enter Mobile Number"
                    required
                  />
                </Col>
              </Row>

              <Row id="formrow">
                <Col sm={12}>
                  <Form.Label>
                    <div className="vr" />
                    Submit Resume(C.V)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    value={this.state.uresume}
                    accept="application/msword, application/pdf"
                    ref={ref => (this.ResumeRef = ref)}
                    name="uresume"
                    required
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-success" type="submit" block>
                Apply
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Container>
    );
  }
}
export default Jobopening;
