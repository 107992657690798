import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Modal
} from "react-bootstrap";
import Axios from "axios";
import { getCurrentDate } from "../../../node_modules/util";
import Constants from "../../constant";
import { trackPromise } from "react-promise-tracker";
import { Snackbar } from "@material-ui/core";

class DealsandOffers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offers: [],
      page: 1,
      loadingMore: false,
      show: false,
      lastPage: 1,
      offer: {},
      name: "",
      number: "",
      offerid: "",address:"" 
    };
    trackPromise( Axios.get(Constants.BASE_URL + "api/offers").then(res => {
      this.setState({ offers: res.data.data, lastPage: res.data.last_page });
    }));
    this.getMoreOffers = this.getMoreOffers.bind(this);
    this.moreOffers = this.moreOffers.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }
  handleClose() {
    this.setState({ show: false });
  }
  handleShow(offer) {
    this.setState({ show: true, offer: offer, offerid: offer.id });
  }
  dataChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  Submithandler = e => {
    e.preventDefault();

    var bodyFormData = new FormData();

    var data = {
      name: this.state.name,
     
      number: this.state.number,
      address: this.state.address,
      offerid: this.state.offerid,
    };
   

    trackPromise(Axios.post(Constants.BASE_URL + "api/GetOffer", data)
      .then(res => {
        if (res.data.success == 1) {
          this.setState({ name: "", number: "", offerid: "",address:"" , show:false,
          alertMessage: res.data.message,
          alertType: "success"});
         
        } else if (res.data.success == 0) {
          this.setState({
            alertMessage: res.data.message,
            alertType: "danger"
          });
        } else {
          this.setState({
            alertMessage: "Something went wrong",
            alertType: "danger"
          });
        }
      })
      .catch(e => {
        this.setState({
          alertMessage: "Something went wrong",
          alertType: "danger"
        });
      }));
  };
  getMoreOffers(page) {
    page++;
    console.log(page);
    this.setState({ loadingMore: true, page: page });
    trackPromise(Axios.get(Constants.BASE_URL + "api/offers?page=" + page).then(res => {
      var offers = this.state.offers;
      if (offers.length > 0) {
        offers = offers.concat(res.data.data);
      } else {
        offers = res.data.data;
      }
      this.setState({ offers: offers, loadingMore: false });
    }));
  }
  moreOffers() {
    this.getMoreOffers(this.state.page);
  }
  render() {
    const { name, number } = this.state;
    return (
      <div>
        <Container className="">
          <Row>
            <Col sm="12">
              
              <h3 className="black-text">Deals And Offers</h3>
              
            </Col>
          </Row>
          <Snackbar
                autoHideDuration={6000}
                severity="error"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={this.state.alertMessage ? true : false}
                message={typeof (this.state.alertMessage) !== 'string' ? this.state.alertMessage : this.state.alertMessage}
                onClose={() => {
                  this.setState({ alertMessage: false });
                }}
              />
          <Row>
            {this.state.offers.map(offer => (
              <Col sm={12} md={6} lg={4} img-fluid>
                <a href={offer.link}>
                  <Card className="mt-3">
                    <Card.Img
                      variant="top"
                      src={Constants.STORAGE_URL + offer.image}
                      style={{height:"200px",width:"100%"}}
                    />
                    <Card.Body style={{ backgroundColor: "#cfd8dc" }}>
                      <Card.Title>{offer.description}</Card.Title>
                      <Button
                        variant="success"
                        block
                        onClick={() => this.handleShow(offer)}
                      >
                        Apply Now
                      </Button>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>
          {this.state.page == this.state.lastPage ? (
            <div></div>
          ) : (
            <Row className="my-3 text-center">
              {!this.state.loadingMore ? (
                <Button
                  size="lg"
                  variant="light"
                  block
                  onClick={this.moreOffers}
                >
                  More{" "}
                </Button>
              ) : (
                <div className="text-center w-100">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </Row>
          )}
          <Modal show={this.state.show} className='offermodal' onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Get Offer</Modal.Title>
            </Modal.Header>
            <Form onSubmit={this.Submithandler}>
              <Modal.Body>
                <Row >
                  <Col sm={12}>
                    <Form.Label>
                      {/* <div className="vr" /> */}
                      Name
                    </Form.Label>
                    <Form.Control
                      className="input"
                      type="text"
                      value={this.state.name}
                      onChange={this.dataChange}
                      name="name"
                      placeholder="Enter Name"
                      required
                    />
                  </Col>
                </Row>
                <Row >
                  <Col sm={12}>
                    <Form.Label>
                      {/* <div className="vr" /> */}
                      Mobile Number
                    </Form.Label>
                    <Form.Control
                      className="input"
                      type="number"
                      value={this.state.number}
                      onChange={this.dataChange}
                      name="number"
                      placeholder="Enter Mobile Number"
                      required
                    />
                  </Col>
                </Row>
                <Row >
                  <Col sm={12}>
                    <Form.Label>
                      {/* <div className="vr" /> */}
                     Address
                    </Form.Label>
                    <Form.Control
                      className="input"
                      type="text"
                      value={this.state.address}
                      onChange={this.dataChange}
                      name="address"
                      placeholder="Enter Address"
                      required
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-success" type="submit" block>
                  Get
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </Container>
      </div>
    );
  }
}
export default DealsandOffers;
