import React from "react";
import { Container, Row, Col, Form, Button, Card, Alert } from "react-bootstrap";
import Constants from "../../constant";
import Axios from "axios";
import { connect } from "react-redux";
import { loginUser } from "../../actions/actions";
import Select from "react-select";
import chroma from 'chroma-js';
import { Paper, Typography, Snackbar } from "@material-ui/core";
import { trackPromise } from "react-promise-tracker";
import { Redirect } from "react-router-dom";



const mapStateToProps = state => {
    return { user: state.user, token: state.auth_token, logged_in: state.logged_in };
}
function mapDispatchToProps(dispatch) {
    return {
        loginUser: userdata => dispatch(loginUser(userdata))
    };
}

class Login extends React.Component {


    colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
                },
            };
        },

    };
    constructor(props) {
        super(props);
        this.state = {
            error:'',
            validated: false,
            categories: [],
            selectedCategory: {},
            listingPlan: "free"
        }
        this.onChangeSubcategory = this.onChangeCategory.bind(this);
        // this.login = this.login.bind(this);
        this.emailRef = React.createRef();
        this.businessNameRef = React.createRef();
        this.contactPersonNameRef = React.createRef();
        this.businessDescriptionRef = React.createRef();
        this.WebsiteLinkRef = React.createRef();
        this.PhoneNumberRef = React.createRef();
        this.whatsappPhoneNumberRef = React.createRef();
        this.PinCodeRef = React.createRef();
        this.businessAddressRef = React.createRef();
        this.imagesRef = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.listingPlanChange = this.listingPlanChange.bind(this);
    }
    componentDidMount() {
        console.log(this.ImagesRef.files.length)
        trackPromise( Axios.get(Constants.BASE_URL + "api/categories").then(res => {
            var categories = [];
            res.data.forEach((category) => {
                var newcategory = { label: category.name, value: category.id, type: "category", color: "purple" }
                categories.push(newcategory);
                category.sub_category.forEach((subcategory) => {
                    categories.push({ label: "-----" + subcategory.name, value: subcategory.id, type: "subcategory", color: "blue" });
                })

            })
            this.setState({ categories });

        }));
    }
    onChangeCategory(category) {
        this.setState({ selectedCategory: category, })

    }
    listingPlanChange(e) {

        console.log(e.target.value);

        this.setState({ listingPlan: e.target.value })
    }
    handleSubmit(event) {


        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (this.state.submitting == true) {
            return false;
        } else {
            this.setState({ submitting: true })
        }
        // console.log(form.checkValidity());
        // if (form.checkValidity() === false) {
        //     this.setState({ submitting: false })
        // } else {
            if (this.state.listingPlan == "free" && this.ImagesRef.files.length > 2) {
                this.setState({ planWarningMessage: "Images more than 2 is not allowed in free plan",submitting: false })
            

            } else if (this.state.listingPlan == "paid" && this.ImagesRef.files.length > 10) {

                this.setState({ planWarningMessage: "Images more than 10 is not allowed in paid plan",submitting: false })
            } else {
                this.setState({ planWarningMessage: "" })
                this.setState({ planWarningMessage: "" })
                let businessName = this.businessNameRef.current.value;
                let contactPersonName = this.contactPersonNameRef.current.value;
                let businessDescription = this.businessDescriptionRef.current.value;
                let websiteLink = this.WebsiteLinkRef.current.value;
                let phoneNumber = this.PhoneNumberRef.current.value;
                let whatsappphoneNumber = this.whatsappPhoneNumberRef.current.value;
                let pinCode = this.PinCodeRef.current.value;
                let businessAddress = this.businessAddressRef.current.value;
                let email = this.emailRef.current.value;
                let images = this.imagesRef.files;
                var bodyFormData = new FormData();

                bodyFormData.set('listingPlan', this.state.listingPlan);
                bodyFormData.set('businessName', businessName);
                bodyFormData.set('contactPersonName', contactPersonName);
                bodyFormData.set('businessDescription', businessDescription);
                bodyFormData.set('websiteLink', websiteLink);
                bodyFormData.set('phoneNumber', phoneNumber);
                bodyFormData.set('whatsappphoneNumber', whatsappphoneNumber);
                bodyFormData.set('pinCode', pinCode);
                bodyFormData.set('businessAddress', businessAddress);
                bodyFormData.set('cat_id', this.state.selectedCategory.value);
                bodyFormData.set('cat_type', this.state.selectedCategory.type);
                bodyFormData.set('email', email);
                for (var i = 0; i < this.ImagesRef.files.length; i++) {
                    bodyFormData.append("images[]", this.ImagesRef.files[i])
                }


                trackPromise(  Axios.post(Constants.BASE_URL_API + 'listing/create', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data', Authorization: "Bearer " + this.props.token } }).then((res) => {
                    this.setState({ submitting: false })

                    if (res.data.STATUS == "SUCCESS") {
                        this.setState({ success: "Listing submitted successfully." });
                        window.scrollTo(0, 0)
                        if (res.data.DATA.listing.listing_type == 'paid') {
                            window.location.href = Constants.BASE_URL + 'listings/order?order_id=' + res.data.DATA.order.id;
                        }else{
                            this.setState({goto:'/mylisting'});
                        }
                        setTimeout(() => {
                            this.setState({ success: "" });
                        }, 6000);

                    } else {
                        this.setState({ error: res.data.ERROR.MESSAGE });
                        window.scrollTo(0, 0)
                        setTimeout(() => {
                            this.setState({ error: "" });
                        }, 6000);
                    }
                }).catch((e, re) => {
                    this.setState({ submitting: false })
                    if (e.response) {
                        if (e.response.data.ERROR) {
                            this.setState({ error: e.response.data.ERROR.MESSAGE });
                            window.scrollTo(0, 0)
                            setTimeout(() => {
                                this.setState({ error: "" });
                            }, 6000);
                        }

                    }

                }))

            }

            this.setState({ validated: true });
            // return false;

        // }



    };

    render() {




        return (
            <div>
            {this.state.goto?<Redirect to={this.state.goto}></Redirect>:<div></div>}
                <Container>

                    
                    <Row className="mt-1 mb-10 text-left">
                        <Col sm="12" md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }}>
                            <Alert className={this.state.success ? 'fadeIn' : 'fadeOut'} variant="success">
                                {this.state.success}
                            </Alert>
                            <Snackbar
                            autoHideDuration={6000}
                            severity="error"
                            anchorOrigin={{vertical:'top',horizontal:'center'}}
                                open={this.state.error?true:false}
                                message={typeof(this.state.error)!=='string'?this.state.error[0]:this.state.error}
                                onClose={()=>{
                                    this.setState({error:false});
                                }}
                            />
             <Paper className='p-3 ' elevation={4} style={{backgroundColor:"#e0e0e0"}}>
               <Typography variant='h3' align="center" className="mb-3"> <span id="datahead">Get </span> Listed</Typography>
            
                                    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                        <Form.Group controlId="formBasicEmail">
                                            {/* <Form.Label>  <div className="vr" />Select Category</Form.Label> */}


                                            <Select
                                                required
                                                placeholder="Select Category or Subcategory"
                                                options={this.state.categories}
                                                styles={this.colourStyles}
                                                onChange={(category) => {
                                                    this.onChangeCategory(category)
                                                }}
                                                defaultValue={this.state.categories[0]}
                                            ></Select>
                                            {!this.props.disabled && (
                                                <Form.Control
                                                    type="text"
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{ opacity: 0, height: 0 }}
                                                    value={this.state.selectedCategory.label}
                                                    required
                                                />
                                            )}


                                            <Form.Control.Feedback type="invalid">
                                                Please provide a category.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label style={{fontWeight:"bold"}}>  <div className="vr" />Select Plan</Form.Label>
                                            <Row>
                                                <Col sm="6">
                                                    <Form.Label for="freelisting">
                                                        <Card style={{ width: '18rem' }}>

                                                            <Card.Body>
                                                                <Card.Title > <Form.Check
                                                                    inline
                                                                    name="plan"
                                                                    type="radio"
                                                                    id="freelisting"
                                                                    onClick={this.listingPlanChange}
                                                                    // label="Free Listing"
                                                                    value="free"
                                                                />
                                                                    Free Listing</Card.Title>
                                                                    <h3 className='text-center'>&#8377; 0</h3>
                                                                <ul>
                                                                    <li>
                                                                        2 images allowed
                                                                        </li>
                                                                    <li>
                                                                        Third-party ads on listing
                                                                        </li>
                                                                    <li>
                                                                        Confirmation required
                                                                        </li>
                                                                </ul>
                                                                {/* <Button variant="primary">Go somewhere</Button> */}
                                                            </Card.Body>
                                                        </Card>

                                                    </Form.Label>

                                                </Col>
                                                <Col sm="6">

                                                    <Form.Label for="paidlisting">
                                                        <Card style={{ width: '18rem' }}>

                                                            <Card.Body>
                                                                <Card.Title>
                                                                    <Form.Check
                                                                        inline
                                                                        name="plan"
                                                                        type="radio"
                                                                        id="paidlisting"
                                                                        onClick={this.listingPlanChange}
                                                                        value="paid"
                                                                        disabled
                                                                    />
                                                                    Paid Listing</Card.Title>
                                                                    <h3 className='text-center'>&#8377; 500</h3>
                                                                
                                                                <ul>
                                                                    <li>
                                                                        10 images allowed
                                                                        </li>
                                                                    <li>
                                                                        No third-party ads on listing
                                                                        </li>
                                                                    <li>
                                                                        Instant Confirmation
                                                                        </li>
                                                                </ul>


                                                                {/* <Button variant="primary">Go somewhere</Button> */}
                                                            </Card.Body>
                                                        </Card>

                                                    </Form.Label>

                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicBusinessName">
                                            {/* <Form.Label> <div className="vr" />Business Name</Form.Label> */}
                                            <Form.Control name="businessname" required type="text" placeholder="Enter Business Name" ref={this.businessNameRef} />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a business name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicContactPersonName">
                                            {/* <Form.Label> <div className="vr" />Contact Person Name</Form.Label> */}
                                            <Form.Control name="contactpersonname" required type="text" placeholder="Enter Contact Person Name" ref={this.contactPersonNameRef} />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a contact person name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                      
                                        <Form.Group controlId="BusinessDescription">
                                            {/* <Form.Label> <div className="vr" />Business Description</Form.Label> */}
                                            <Form.Control required as="textarea" rows="3" placeholder="Enter Business Description" ref={this.businessDescriptionRef} />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a business description.
                                            </Form.Control.Feedback>
                                        </Form.Group>


                                        <Form.Group controlId="formBasicWebsiteLink">
                                            {/* <Form.Label> <div className="vr" />Website Link</Form.Label> */}
                                            <Form.Control name="websitelink" type="text" placeholder="Enter Website Link" ref={this.WebsiteLinkRef} />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid link.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPhoneNumber">
                                            {/* <Form.Label> <div className="vr" />Contact Phone Number</Form.Label> */}
                                            <Form.Control required name="websitelink" type="text" placeholder="Enter Phone Number"  ref={this.PhoneNumberRef} />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid contact phone number.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicwhatsappPhoneNumber">
                                            {/* <Form.Label> <div className="vr" />Whatsapp Phone Number</Form.Label> */}
                                            <Form.Control required name="websitelink" type="text" placeholder="Enter Whatsapp Number" minLength="10" maxLength="10" ref={this.whatsappPhoneNumberRef} />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid contact phone number.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicBusinessName">
                                            {/* <Form.Label> <div className="vr" />Email Address</Form.Label> */}
                                            <Form.Control name="businessname" type="text" placeholder="Enter Email Address" ref={this.emailRef} />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a email address.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="BusinessAddress">
                                            {/* <Form.Label> <div className="vr" />Business Address</Form.Label> */}
                                            <Form.Control required as="textarea" rows="3" placeholder="Enter Business Address" ref={this.businessAddressRef} />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a business address.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPinCode">
                                            {/* <Form.Label> <div className="vr" />Pin Code</Form.Label> */}
                                            <Form.Control required name="websitelink" type="text" placeholder="Enter Pin Code" minLength="6" maxLength="6" ref={this.PinCodeRef} />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid pin code.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPinCode">
                                            <Form.Label style={{fontWeight:"bold"}}> <div className="vr" />Images</Form.Label>
                                            <Form.Control required name="images" type="file" multiple ref={(ref) => this.ImagesRef = ref}
                                            />
                                            <div className="text-danger" ref="fileMessageRef">{this.state.planWarningMessage}</div>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide atleast one image.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Button variant="success"  type="submit"  >
                                            Submit
                                        </Button>
                                    </Form>
                              </Paper>
                        </Col>
                    </Row>
                </Container>
            </div >
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);


