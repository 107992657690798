import React from "react";
import "./Header.css";
import Constants from "../../constant";
import Axios from "axios";
import { Navbar, Nav, NavDropdown, Badge, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/actions";
import logo from "./logo.gif";
import { trackPromise } from "react-promise-tracker";
const mapStateToProps = state => {
  return {
    user: state.user,
    token: state.auth_token,
    logged_in: state.logged_in
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logoutUser: () => dispatch(logoutUser())
  };
}

var header = props => {
  const logout = () => {
    trackPromise( Axios.get(Constants.BASE_URL_API + "logout", {
      headers: { Authorization: "Bearer " + props.token }
    })
      .then(res => {
        if (res.data.STATUS == "SUCCESS") {
          props.logoutUser();
        } else {
          this.setState({ error: res.data.ERROR.MESSAGE[0] });
          setTimeout(() => {
            this.setState({ error: "" });
          }, 6000);
        }
      })
      .catch((e, re) => {
        if (e.response.data.ERROR) {
          this.setState({ error: e.response.data.ERROR.MESSAGE[0] });
          setTimeout(() => {
            this.setState({ error: "" });
          }, 6000);
        }
      }));
  };
  console.log(props);
  
  return (
    <div className="mb-3">
         <Navbar
         
            bg="dark"
            fixed="top"
            variant="dark"
          >

          <span className="headerbar">HelpLine No:+91-9878615774</span>
          {/* <label>
            <a href="/login" className="data">
              Login
            </a>
          </label>
          <label>
            <a href="/signup" className="data">
              Sign Up
            </a>
          </label> */}
           {props.logged_in ? (
             <div></div>
              ) : (
                <Nav>
                  <Nav.Link href="/login">Login </Nav.Link>
                  <Nav.Link href="/signup">Sign Up </Nav.Link>
                </Nav>
              )}
       
        </Navbar>
      
      <header>
        <div >
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="light"
            fixed="top"
            className=" navbarset"
          >
           
            <Navbar.Brand href="/">
            <img className=" imagehead" src={logo} />
              {/* <span style={{ color: "#CE4364" }}>Esmart</span>
              <span style={{ color: "#3AA2EB" }}>Services</span> */}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto links">
               
              </Nav>
              <Nav>
              <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/Aboutus">About Us</Nav.Link>
                <Nav.Link href="/getlisted">Get Listed</Nav.Link>
                <Nav.Link href="/businessdirectory">
                  Business Directory
                </Nav.Link>
                {/* <Nav.Link href="/servicerequest">Service Request</Nav.Link> */}
                <Nav.Link href="/jobopening">Job Opening </Nav.Link>
                <Nav.Link href="/Contactus">Contact Us</Nav.Link>
                {props.logged_in ? (
                <Nav>
                  <NavDropdown
                    title={props.user.name}
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item href="/mylisting">
                      My Listings
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={logout} href="#">
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              ) : (
               <div></div>
              )}
       
              </Nav>
             
            </Navbar.Collapse>
          </Navbar>
        </div>
      </header>
    </div>
    
  );
};

header = connect(mapStateToProps, mapDispatchToProps)(header);

export default header;
