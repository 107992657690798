// import React from "react";
// import { Container, Row, Col, Badge, Card } from "react-bootstrap";
// import Image from "react-bootstrap/Image";
// import News from "./images/news.png";
// import Deal from "./images/deal.png";
// import Results from "./images/student.png";
// import CurrentAffair from "./images/meeting.png";
// import "./tabs.css";
// import Axios from "axios";
// import Constants from "../../constant";
// import { Link } from "react-router-dom";
// import { trackPromise } from "react-promise-tracker";

// class tabs extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { offers: [],currentaffair:[] };
//     trackPromise( Axios.get(Constants.BASE_URL + "api/offers/today").then(res => {
//       this.setState({ offers: res.data });
//     }));
//     trackPromise(  Axios.get(Constants.BASE_URL + "api/currentaffair/today").then(res => {
//       this.setState({ currentaffair: res.data });
//     }));
//   }
//   render() {
//     return (
//       <div className="mt-5">
//         <Container>
//           <Row>
//             <Col xs="3" sm="3" md={3} className="tabs p-1">
//               <Link to="/tabs/news">
//                 <Card className="mt-3 p-0">
//                   <Card.Img variant="top" className="tabsimage" src={News} />
//                   <Card.Body className="py-1 px-0">
//                     <Card.Text className="p-0">News</Card.Text>
//                   </Card.Body>
//                 </Card>
//               </Link>
//             </Col>

//             <Col xs="3" sm="3" md={3} className="tabs p-1">
//               <Badge variant="danger" className="homebadge">
//                 <span id="glow">{this.state.offers.length}</span>
//               </Badge>
//               <Link to="/tabs/deals">
//                 <Card className="mt-3 ">
//                   <Card.Img variant="top" className="tabsimage" src={Deal} />
//                   <Card.Body className="py-1 px-0">
//                     <Card.Text className="py-0">Deals</Card.Text>
//                   </Card.Body>
//                 </Card>
//               </Link>
//             </Col>
//             <Col xs="3" sm="3" md={3} className="tabs p-1">
//             <Link to="/tabs/results">
//                 <Card className="mt-3 ">
//                   <Card.Img variant="top" className="tabsimage" src={Results}  />
//                   <Card.Body className="py-1 px-0">
//                     <Card.Text className="py-0">Results</Card.Text>
//                   </Card.Body>
//                 </Card>
//               </Link>
//             </Col>
//             <Col xs="3" sm="3" md={3} className="tabs p-1">
//               <Badge variant="danger" className="homebadge">
//                 <span id="glow">{this.state.currentaffair.length}</span>
//               </Badge>
//               <Link to="/tabs/affair">
//                 <Card className="mt-3 ">
//                   <Card.Img variant="top" className="tabsimage" src={CurrentAffair} />
//                   <Card.Body className="py-1 px-0">
//                     <Card.Text className="py-0">Current Affair</Card.Text>
//                   </Card.Body>
//                 </Card>
//               </Link>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     );
//   }
// }
// export default tabs;


import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import HomeIcon from '@material-ui/icons/Home';
import LanguageIcon from '@material-ui/icons/Language';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import SchoolIcon from '@material-ui/icons/School';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Homepage from '../Homepage/Homepage';
import News from "./news";
import DealsAndOffers from "./deals&offers";
import Affiars from "./currentaffairs";
import Results from "./results";
import { Badge, Card, BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import HomeCarousel from "../Homepage/carousel/Carousel";
import BusinessIcon from '@material-ui/icons/Business';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={1}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

export default function HomeTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div >
       <HomeCarousel />
      <Card  color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab wrapped icon={
          <BusinessIcon></BusinessIcon>
} label="Listings" {...a11yProps(0)} />
          <Tab wrapped icon={<LanguageIcon></LanguageIcon>} label="News" {...a11yProps(1)} />
          <Tab wrapped  icon={<LocalMallIcon></LocalMallIcon>} label="Deals" {...a11yProps(2)} />
          <Tab wrapped icon={<NotificationsActiveIcon></NotificationsActiveIcon>} label="Current Affairs" {...a11yProps(3)} />
        </Tabs>
      </Card>
    
        <TabPanel value={value} index={0} dir={theme.direction}>
         
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        <News />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
        <DealsAndOffers />
        </TabPanel>
      
        <TabPanel value={value} index={3} dir={theme.direction}>
        <Affiars></Affiars>
        
        </TabPanel>
        <Homepage />
       
    </div>
  );
}