import React from "react";
import "./App.css";
import Header from "./components/header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
// import Homepage from "./components/Homepage/Homepage";
import HomeTabs from "./components/tabs/tabs";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Products from "./components/products/Products";
import News from "./components/tabs/news";
import DealsAndOffers from "./components/tabs/deals&offers";
import Affiars from "./components/tabs/currentaffairs";
import Results from "./components/tabs/results";
import Footer from "./components/footer/footer";
import Login from "./components/login/Login";
import Jobopening from "./components/Homepage/jobopening/jobopening";
import ContactUs from "./components/Homepage/contactus";
import AboutUs from "./components/Homepage/aboutus";
import Signup from "./components/signup/Signup";
import IsLoggedIn from "./middlewares/isLoggedIn";
import IsNotLoggedIn from "./middlewares/isNotLoggedIn";
import GetListed from "./components/getListed/GetListed";
import Listings from "./components/listings/Listings";
import ListingDetail from "./components/listings/ListingDetail";
import MyListings from "./components/mylisting/MyListing";
import Notifications from "./components/notification/notification";
import SiteSidebar from "./components/sidebar/SiteSidebar";
import Loader from "./components/Loader/loader"
import PrivacyPolicy from "./components/privacypolicy/privacypolicy";
import Service from './components/Homepage/Service/ServiceRequest';

function App(props) {


  return (
    <div className="App">

      <Router>

        {/* <Header name="Esmart Services" /> */}
        <SiteSidebar></SiteSidebar>
        <div className="app-body">

          <Switch className="mb-5">
            <Route exact path="/">
              <HomeTabs />
            </Route>
            <Route path="/login">
              <IsNotLoggedIn>
                <Login />
              </IsNotLoggedIn>
            </Route>
            <Route path="/signup">
              <IsNotLoggedIn>
                <Signup />
              </IsNotLoggedIn>
            </Route>
            <Route path="/getlisted">

              <GetListed />

            </Route>
            <Route path="/mylisting">
              <IsLoggedIn>
                <MyListings />
              </IsLoggedIn>
            </Route>
            <Route
              exact
              path="/businessdirectory/:id"
              component={Listings}
            ></Route>
            <Route path="/businessdirectory/">
              <Listings />
            </Route>

            <Route path="/listingdetail/:id" component={ListingDetail}></Route>
            <Route path="/products">
              <Products />
            </Route>
            <Route path="/tabs/news">
              <News />
            </Route>
            <Route path="/tabs/deals">
              <DealsAndOffers />
            </Route>
            <Route path="/tabs/affair">
              <Affiars />
            </Route>
            <Route path="/tabs/results">
              <Results />
            </Route>
            <Route path="/jobopening">
              <Jobopening />
            </Route>
            <Route path="/Contactus">
              <ContactUs />
            </Route>
            <Route path="/Aboutus">
              <AboutUs />
            </Route>
            <Route path="/Notifications">
              <Notifications />
            </Route>
            <Route path="/servicerequest">
              <Service />
            </Route>

            <Route path="/privacypolicy">
              <PrivacyPolicy />
            </Route>
          </Switch>
        </div>

      </Router>
      <Loader></Loader>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
