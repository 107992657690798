import { LOGIN_USER,LOGOUT_USER } from "../actions/action-types";


const initialState = localStorage.state ? JSON.parse(localStorage.state) : {
    user: {},
    logged_in:false,
    auth_token:""
};
function rootReducer(state = initialState, action) {
    
    switch(action.type){
        case LOGIN_USER:{
            state = { ...state, user: action.payload.user ,auth_token:action.payload.token ,logged_in:true }
            break;
        }
        case LOGOUT_USER:{
            state = { ...state, user: {} ,auth_token:"" ,logged_in:false }
        }
    }
    if (action.type === LOGIN_USER) {

    }


    localStorage.state = JSON.stringify(state)
    return state;
};
export default rootReducer;