import React from "react";
import Adsone from "./carousel/Carouselone";
import Adstwo from "./carousel/Carouseltwo";
import Adsthree from "./carousel/Carouselthree";
import { Container, Row, Col, Spinner, Image } from "react-bootstrap";
import Category from "./categories/Category";
import Axios from "axios";
import Constants from "../../constant";
import Tabs from "../tabs/tabs";
import Listings from "../listings_home/Listings";
import { trackPromise } from "react-promise-tracker";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Paper,
  Button,
  Card,
  Typography,
  CardContent,
  Snackbar,
} from "@material-ui/core";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import CommuteIcon from "@material-ui/icons/Commute";
import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly";
import MobileScreenShareIcon from "@material-ui/icons/MobileScreenShare";
import TvIcon from "@material-ui/icons/Tv";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import OpacityIcon from "@material-ui/icons/Opacity";
import WifiIcon from "@material-ui/icons/Wifi";
import CallEndIcon from "@material-ui/icons/CallEnd";
import HotelIcon from "@material-ui/icons/Hotel";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import { Link } from "react-router-dom";
// import SearchTab from "../search/seach";
class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { categories: [], Ads: [], page: 1, lastPage: 1 };
    trackPromise(
      trackPromise(
        Axios.get(Constants.BASE_URL + "api/categories").then((res) => {
          this.setState({
            categories: res.data,
            // lastPage: res.data.last_page,
          });
        })
      )
    );
    // trackPromise(
    // Axios.get(Constants.BASE_URL + "api/ads").then(res => {
    //   this.setState({ Ads: res.data });
    // })
    // );
    this.getMoreCategory = this.getMoreCategory.bind(this);
    this.moreCategories = this.moreCategories.bind(this);
    this.comingSoonSnackBar = this.comingSoonSnackBar.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
  }
  getMoreCategory(page) {
    page++;
    console.log(page);
    this.setState({ loadingMore: true, page: page });
    trackPromise(
      Axios.get(Constants.BASE_URL + "api/pagecategories?page=" + page).then(
        (res) => {
          var categories = this.state.categories;
          if (categories.length > 0) {
            categories = categories.concat(res.data.data);
          } else {
            categories = res.data.data;
          }
          this.setState({ categories: categories, loadingMore: false });
        }
      )
    );
  }
  moreCategories() {
    this.getMoreCategory(this.state.page);
  }
  comingSoonSnackBar() {
    this.setState({
      comingsoonopen: true,
    });
    var that = this;
    setTimeout(function () {
      that.closeSnackBar();
    }, 6000);
  }
  closeSnackBar() {
    this.setState({
      comingsoonopen: false,
    });
  }
  render() {
    return (
      <div className="">
        <Snackbar
          open={this.state.comingsoonopen}
          onClose={this.closeSnackBar}
          message="Coming Soon!"
        />
        <div>
          <Container>
            <Row className="mb-1">
              <Col sm="12">
                <Adsone />
              </Col>
            </Row>
            <Row>
              <Col sm="1"></Col>
              <Col sm="10">
                {/* <SearchTab /> */}
                <div className="mt-1">
                  <Paper className="p-1" elevation={3}>
                    <Row className="mt-2">
                      <Col sm={12}>
                        <h2>
                          <span
                            style={{
                              color: "#3AA2EB",
                              // fontFamily: "Black Ops One, cursive"
                            }}
                          >
                            Categories
                          </span>
                        </h2>
                      </Col>
                    </Row>

                    <Row style={{height:'391px',overflowX:'scroll',}}>
                      {this.state.categories.map((value, index) => {
                        return (
                          <Col key={index} xs="3">
                            <Category key={index} data={value} />{" "}
                          </Col>
                        );
                      })}
                    </Row>

                    {this.state.page == this.state.lastPage ? (
                      <div></div>
                    ) : (
                      <Row className="my-3 text-center">
                        <Col sm="12">
                          {!this.state.loadingMore ? (
                            <Button
                              fullWidth="true"
                              variant="contained"
                              color="secondary"
                              onClick={this.moreCategories}
                            >
                              View More{" "}
                            </Button>
                          ) : (
                            <div className="text-center w-100">
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    )}
                  </Paper>

                  <Paper className="mt-2 py-2">
                    <Listings />
                  </Paper>
                </div>
              </Col>
            </Row>
            {/* <Row className="my-3">
              <Col sm="12">
                <Adstwo />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="3" sm="3">
                <Link to="/tabs/results">
                  <Card className="homerowcard">
                    <SchoolIcon fontSize="large"></SchoolIcon>
                  </Card>
                  <Typography className="label_text" gutterBottom>
                    Result
                  </Typography>
                </Link>
              </Col>
              <Col
                xs="3"
                sm="3"
                onClick={() => {
                  this.comingSoonSnackBar();
                }}
              >
                <Card className="homerowcard">
                  <CommuteIcon fontSize="large"></CommuteIcon>
                </Card>
                <Typography className="label_text" gutterBottom>
                  Transport
                </Typography>
              </Col>
              <Col xs="3" sm="3">
                <Link to="/jobopening">
                  <Card className="homerowcard">
                    <WorkIcon fontSize="large"></WorkIcon>
                  </Card>
                  <Typography className="label_text" gutterBottom>
                    Job Openings
                  </Typography>
                </Link>
              </Col>
              <Col xs="3" sm="3">
                <Link to="/servicerequest">
                  <Card className="homerowcard">
                    <BusinessCenterIcon fontSize="large"></BusinessCenterIcon>
                  </Card>
                  <Typography className="label_text" gutterBottom>
                   Services
                  </Typography>
                </Link>
              </Col>
            </Row> */}
            <Row className="mt-2">
              <Col xs="12">
                <Card className="p-3">
                  <Typography gutterBottom variant="h4">
                    Upcoming Services
                  </Typography>
                  <Row
                    onClick={() => {
                      this.comingSoonSnackBar();
                    }}
                  >
                    <Col xs="4" style={{fontSize:"50px"}}>
                      <MobileFriendlyIcon fontSize="inherit"></MobileFriendlyIcon>
                      <Typography className="label_text label_service" gutterBottom>
                        Prepaid
                      </Typography>
                    </Col>
                    <Col xs="4" style={{fontSize:"50px"}}>
                      <MobileScreenShareIcon fontSize="inherit"></MobileScreenShareIcon>
                      <Typography className="label_text label_service" gutterBottom>
                        Postpaid
                      </Typography>
                    </Col>

                    <Col xs="4" style={{fontSize:"50px"}}>
                      <WifiIcon fontSize="large"></WifiIcon>
                      <Typography className="label_text label_service" gutterBottom>
                        Broadband
                      </Typography>
                    </Col>
                    <Col xs="4" style={{fontSize:"50px"}}>
                      <CallEndIcon fontSize="large"></CallEndIcon>
                      <Typography className="label_text label_service" gutterBottom>
                        Landline
                      </Typography>
                    </Col>
                    <Col xs="4" style={{fontSize:"50px"}}>
                      <TvIcon fontSize="large"></TvIcon>
                      <Typography className="label_text label_service" gutterBottom>
                        DTH
                      </Typography>
                    </Col>
                    <Col xs="4" style={{fontSize:"50px"}}>
                      <EmojiObjectsIcon fontSize="large"></EmojiObjectsIcon>
                      <Typography className="label_text label_service" gutterBottom>
                        Electricity
                      </Typography>
                    </Col>
                    <Col xs="4" style={{fontSize:"50px"}}>
                      <OpacityIcon fontSize="large"></OpacityIcon>
                      <Typography className="label_text label_service" gutterBottom>
                        Water
                      </Typography>
                    </Col>
                    <Col xs="4" style={{fontSize:"50px"}}>
                      <HotelIcon fontSize="large"></HotelIcon>
                      <Typography className="label_text label_service" gutterBottom>
                        Hotels
                      </Typography>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row className="my-3">
              <Adsthree></Adsthree>
            </Row>
            <Typography className="mb-3" variant="caption">
              Powered by ESS Group | Developed by{" "}
              <a href="https://gsdsquare.com">GSD Square Solutions</a>
            </Typography>
          </Container>
        </div>
      </div>
    );
  }
}
export default Homepage;
