import React from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import { MDBIcon } from "mdbreact";
import Axios from "axios";
import Constants from "../../constant";
import "./contactus.css";

import Adsone from "./carousel/Carouselone";
import Adstwo from "./carousel/Carouseltwo";
import { trackPromise } from "react-promise-tracker";
import { Paper, TextField } from "@material-ui/core";
class Contactus extends React.Component {
  constructor(props) {
    super(props);
    this.state = { info: [], uname: "", umail: "", unumber: "", umsg: "", uaddress: ""};
   
    trackPromise( Axios.get(Constants.BASE_URL + "api/contactus").then(res => { 
      this.setState({ info: res.data });
    }));
  }
  dataChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  Submithandler = e => {
    e.preventDefault();
    var data = {
      name: this.state.uname,
      mail: this.state.umail,
      number: this.state.unumber,
      msg: this.state.umsg,
      address:this.state.uaddress,
    };
    trackPromise(
    Axios.post(Constants.BASE_URL + "api/requestmsg", data)
      .then(res => {
        if (res.data.success == 1) {
          this.setState({ uname: "", umail: "", unumber: "", umsg: "" ,uaddress:""});
          this.setState({
            alertMessage: res.data.message,
            alertType: "success"
          });
        } else if (res.data.success == 0) {
          this.setState({
            alertMessage: res.data.message,
            alertType: "danger"
          });
        } else {
          this.setState({
            alertMessage: "Something went wrong",
            alertType: "danger"
          });
        }
      })
      .catch(e => {
        this.setState({
          alertMessage: "Something went wrong",
          alertType: "danger"
        });
      })
    );
  };

  render() {
    const { uname, umail, unumber, umsg , uaddress} = this.state;
    return (
      <div>
        <Container>
          {this.state.alertMessage != "" ? (
            <Alert variant={this.state.alertType}>
              {this.state.alertMessage}
            </Alert>
          ) : (
            <div></div>
          )}
          <Row style={{ paddingTop: "20px" }}>
            <Col sm={12}>
              <Adsone />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Row>
                <Col sm={12}>
                  <h1 >
                    <span id="datahead">Contact</span> us
                  </h1>
                </Col>
              </Row>
              
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} >
                <Paper className='p-2' style={{backgroundColor:"#f5f5f5 "}}>
                  <Form onSubmit={this.Submithandler}>
                    <Row >
                      <Col sm={12} >
                        {/* <Form.Label>
                          <div className="vr" />
                          Name
                        </Form.Label> */}
                        <TextField
                          variant="outlined"
                          label="Enter Name"
                         fullWidth
                          type="text"
                          value={this.state.uname}
                          onChange={this.dataChange}
                          name="uname"
                         
                        />
                      </Col>
                    </Row>
                    <Row >
                      <Col sm={12} className="mt-2">
                        {/* <Form.Label>
                          <div className="vr" />
                          Email address
                        </Form.Label> */}
                        <TextField
                          variant="outlined"
                          label="Enter email"
                          fullWidth
                          type="email"
                          name="umail"
                          value={this.state.umail}
                          onChange={this.dataChange}
                          
                        />
                        {/* <Form.Text className="text-muted">
                          <span className="text ">
                            We'll never share your email with anyone else.
                          </span>
                        </Form.Text> */}
                      </Col>
                    </Row>
                    <Row >
                      <Col sm={12} className="mt-2">
                        {/* <Form.Label>
                          <div className="vr" />
                          Mobile No.
                        </Form.Label> */}
                        <TextField
                          variant="outlined"
                          label="Enter mobile"
                          fullWidth
                          type="number"
                          name="unumber"
                          value={this.state.unumber}
                          onChange={this.dataChange}
                          
                        />
                      </Col>
                    </Row>
                    <Row >
                      <Col sm={12} className="mt-2">
                        {/* <Form.Label>
                          <div className="vr" />
                          Message
                        </Form.Label> */}
                        <TextField
                          variant="outlined"
                          label="Enter Message"
                         fullWidth
                         multiline
                         rows={3}
                          name="umsg"
                          value={this.state.umsg}
                          onChange={this.dataChange}
                          type="text"
                          placeholder="Tell us more"
                        />
                      </Col>
                    </Row>
                    <Row >
                      <Col sm={12} className="mt-2">
                        {/* <Form.Label>
                          <div className="vr" />
                          Address
                        </Form.Label> */}
                        <TextField
                          variant="outlined"
                          label="Enter Address"
                         fullWidth
                         multiline
                         rows="3"
                          type="text"
                          value={this.state.uaddress}
                          onChange={this.dataChange}
                          name="uaddress"
                       
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} className="mt-2">
                        <Button variant="success" type="submit" block>
                          Submit
                        </Button>
                      </Col>
                    </Row>
                    <Row id="formrow"></Row>
                  </Form>
                  </Paper>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} >
                <Paper className='p-2'>
                  <Row className="mt-3 ml-2 text-left">
                    <Col sm={12} style={{paddingTop:"20px"}}>
                      <div className="vr" />

                      <span>
                        <b>Head Office</b>
                      </span>
                      <br />
                      <i
                        className="material-icons"
                        id="addicon"
                        style={{ color: "skyblue", size: "10px" }}
                      >
                        business
                      </i>
                      <span id="address">
                        {this.state.info.map(data => data.address)}
                      </span>
                      <span id="add">
                        {this.state.info.map(data => ", " + data.city + ",")}
                      </span>
                      <span id="add">
                        {this.state.info.map(data => data.state + ".")}
                      </span>
                    </Col>
                    <Col sm={12} className="pt-2">
                      <div className="vr" />
                      <span>
                        <b>Call Us</b>
                      </span>
                      <br />
                      <i
                        className="material-icons"
                        id="addicon"
                        style={{ color: "skyblue", size: "10px" }}
                      >
                        phone_in_talk
                      </i>
                      <span id="address">
                        For Any Inquiry or Question Click On Any Button To Call
                        Us.
                      </span>
                      <br />
                      <Button
                        variant="outline-info"
                        fluid
                        href={
                          "tel:" + this.state.info.map(data => data.mobileno)
                        }
                        id="btn"
                      >
                        {this.state.info.map(data => data.mobileno)}
                      </Button>
                      <Button
                        variant="outline-info"
                        fluid
                        href={
                          "tel:" + this.state.info.map(data => data.mbileno2)
                        }
                        id="btn"
                      >
                        {this.state.info.map(data => data.mbileno2)}
                      </Button>
                    </Col>
                    <Col sm={12} className="pt-2">
                      <div className="vr" />
                      <span>
                        <b>Email Us</b>
                      </span>
                      <br />
                      <i 
                        className="material-icons"
                        id="addicon"
                        style={{ color: "skyblue", size: "10px" }}
                      >
                        email
                      </i>
                      <span id="address">
                        For Any Inquiry or Question Click On Button To Email Us.
                      </span>
                      <br />

                      <Button
                        variant="outline-dark"
                        fluid
                        href={
                          "mailto:" + this.state.info.map(data => data.mobileno)
                        }
                        id="btnemail"
                      >
                        {this.state.info.map(data => data.user.email)}
                      </Button>
                    </Col>
                    <Col sm={12} className="pt-2">
                      <div className="vr" />
                      <span>
                        <b>Bank Details</b>
                      </span>
                      <br></br>
                      <span id="bank">
                        Bank Name: {this.state.info.map(data => data.bankname)}
                        <br />
                      </span>
                      <span id="bank">
                        Branch: {this.state.info.map(data => data.branchname)}
                        <br />
                      </span>
                      <span id="bank">
                        Account Number:{" "}
                        {this.state.info.map(data => data.accountno)}
                        <br />
                      </span>
                      <span id="bank">
                        Account Name:{" "}
                        {this.state.info.map(data => data.account_name)}
                        <br />
                      </span>
                      <span id="bank">
                        IFSC Code: {this.state.info.map(data => data.ifsc_code)}
                        <br />
                      </span>
                      <br />
                    </Col>
                  </Row>
                  </Paper>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d214.6370397604966!2d74.25580759100995!3d30.600329282992252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39177f1ae65fc8bf%3A0x8cc5185fe8febfd2!2sBahmani%20Bazar%2C%20Jalalabad%2C%20Punjab%20152024!5e0!3m2!1sen!2sin!4v1577690849343!5m2!1sen!2sin"
                id="map"
              ></iframe>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className="mt-1">
              <Adstwo />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Contactus;
