// // import { Navbar, Nav, NavItem, Button, Glyphicon } from 'react-bootstrap';

// import React, { Component } from 'react';
// import { Navbar } from 'react-bootstrap';
// import  {Link} from "react-router-dom";

// import './sidebar.css';

// export default class SiteSidebar extends Component {

//   constructor(props) {
//     super(props);

//     this.state = {
//       isVisible: false,
//     };
//     this.toggleSidebar = this.toggleSidebar.bind(this);
//   }
//   componentDidMount() {

//   }

//   toggleSidebar() {

//     this.setState({ isVisible: !this.state.isVisible });
//   }



//   render() {
//     const classes = useStyles();
//     const theme = useTheme();
//     const [open, setOpen] = React.useState(false);

//     const handleDrawerOpen = () => {
//       this.setState({ open: true });
//       setOpen(true);
//     };

//     const handleDrawerClose = () => {
//       this.setState({ open: false });

//     };
//     return (
//       <div>
//  <AppBar
//         position="fixed"
//         className={clsx(classes.appBar, {
//           [classes.appBarShift]: open,
//         })}
//       >
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             onClick={handleDrawerOpen}
//             edge="start"
//             className={clsx(classes.menuButton, open && classes.hide)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" noWrap>
//             Persistent drawer
//           </Typography>
//         </Toolbar>
//       </AppBar>
//       <Drawer
//         className={classes.drawer}
//         variant="persistent"
//         anchor="left"
//         open={open}
//         classes={{
//           paper: classes.drawerPaper,
//         }}
//       >
//         <div className={classes.drawerHeader}>
//           <IconButton onClick={handleDrawerClose}>
//             {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
//           </IconButton>
//         </div>
//         <Divider />
//         <List>
//           {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
//             <ListItem button key={text}>
//               <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
//               <ListItemText primary={text} />
//             </ListItem>
//           ))}
//         </List>
//         <Divider />
//         <List>
//           {['All mail', 'Trash', 'Spam'].map((text, index) => (
//             <ListItem button key={text}>
//               <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
//               <ListItemText primary={text} />
//             </ListItem>
//           ))}
//         </List>
//       </Drawer>




//         {/* <div className={`sidebar ${this.state.isVisible ? 'active' : ''} `}>

//           <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
//             <div className="toggle-sidebar-btn" onClick={this.toggleSidebar}><a>
//               <span></span>
//               <span></span>
//               <span></span>
//             </a>
//             </div>
//             <Navbar.Brand href="/">GSD Live</Navbar.Brand>

//           </Navbar>



//           <ul className=" ">
//           <li><a className="sidebar-link" href="/">Home</a></li>
//           <li><a className="sidebar-link" href="/getlisted">Get Listed</a></li>
//           <li><a className="sidebar-link" href="/businessdirectory">Business Directory</a></li>
//           <li><a className="sidebar-link" href="/jobopening">Job Opening </a></li>
//           <li><Link to="/Contactus" className="sidebar-link" label="Contact Us"></Link></li>
//           <li><a className="sidebar-link"href="/Aboutus">About Us</a></li>
//             <li><a className="sidebar-link" href="/">Home</a></li>

//           </ul>
//         </div> */}
//       </div>
//     );
//   }
// }



import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import { Redirect } from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { connect } from "react-redux";
import { logoutUser } from "../../actions/actions";
import Constants from "../../constant";
import Axios from "axios";
import { Link } from "react-router-dom";
import { trackPromise } from 'react-promise-tracker';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { Badge, BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";

const mapStateToProps = state => {
  return {
    user: state.user,
    token: state.auth_token,
    logged_in: state.logged_in
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logoutUser: () => dispatch(logoutUser())
  };
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  stickToBottom: {
    zIndex:500,
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  root: {
    display: 'flex',
    flexGrow: '1'
  },
  title: {
    flexGrow: 1,
    lineHeight: 'normal'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

var SiteSidebar = (props) => {
  const [value, setValue] = React.useState('/');

const handleBottomChange = (event, newValue) => {
  setValue(newValue);
  setGoto(newValue)
};

  const [notificationCount, setNotificationCount] = useState(0);
  const logout = () => {
    trackPromise(Axios.get(Constants.BASE_URL_API + "logout", {
      headers: { Authorization: "Bearer " + props.token }
    })
      .then(res => {
        if (res.data.STATUS == "SUCCESS") {
          props.logoutUser();
        } else {
          this.setState({ error: res.data.ERROR.MESSAGE[0] });
          setTimeout(() => {
            this.setState({ error: "" });
          }, 6000);
        }
      })
      .catch((e, re) => {
        if (e.response.data.ERROR) {
          this.setState({ error: e.response.data.ERROR.MESSAGE[0] });
          setTimeout(() => {
            this.setState({ error: "" });
          }, 6000);
        }
      }));
  };
  const getnotificationcount = () => {
    trackPromise(Axios.get(Constants.BASE_URL_API + "notificationcount")
      .then(res => {
        setNotificationCount(res.data.count);
      })
      .catch((e, re) => {

      }));
  };
  useEffect(() => {
    getnotificationcount();
  }, [])
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [goto, setGoto] = React.useState(false);
  const [userListOpen, setUserListOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div >
      {goto !== false ? <Redirect to={goto}></Redirect> : <div></div>}
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.title}>
            <div style={{ fontSize: 'x-large' }}>ESmart Services</div>
            <div style={{ fontSize: 'small', fontWeight: 300 }}>Search Anywhere , Anytime</div>
          </div>
          {/* <Typography variant="h6" className={classes.title} noWrap>
            Esmart Services
          </Typography>
          <br></br>
          <Typography variant="h6" className={classes.title} noWrap>
            Esmart Services
          </Typography> */}
          <Link to='/businessdirectory' style={{ color: 'white' }}>
            <IconButton aria-label="search" color="inherit">
              <SearchIcon />
            </IconButton>
          </Link>
          <Link to='/notifications' style={{ color: 'white' }}>

            <IconButton aria-label="search" color="inherit">
              <Badge badgeContent={notificationCount} color="secondary">
                <NotificationsActiveIcon />
              </Badge>
            </IconButton>

          </Link>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />


        {props.logged_in ? (
          <List>
            <ListItem button onClick={() => {
              setUserListOpen(!userListOpen)
            }}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={props.user.name} />
              {userListOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={userListOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to='/mylisting'>
                  <ListItem button onClick={() => {
                    //  setGoto('/mylisting');
                    setOpen(false);
                  }} className={classes.nested}>
                    <ListItemIcon>

                    </ListItemIcon>
                    <ListItemText primary="My Listing" />
                  </ListItem>
                </Link>
                <ListItem button onClick={() => {
                  logout();
                }} className={classes.nested}>
                  <ListItemIcon>

                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>

              </List>
            </Collapse>
          </List>
        ) : (
            <List>
              {[{ name: 'Login', link: '/login' }, { name: 'Sign Up', link: '/signup' }].map((sidelnk, index) => (
                <Link to={sidelnk.link} key={index}>
                  <ListItem button >
                    {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                    <ListItemText primary={sidelnk.name} onClick={() => {

                      setOpen(false);
                    }} />
                  </ListItem>
                </Link>
              ))}
            </List>)}


        <Divider />
        <List>
          {[{ name: 'Home', link: '/' },  { name: 'Get Listed', link: '/getlisted' }, { name: 'Business Directory', link: '/businessdirectory' },{ name: 'Contact Us', link: '/Contactus' }].map((sidelnk, index) => (
            <Link to={sidelnk.link} key={index}>
              <ListItem button onClick={() => {
                // setGoto(sidelnk.link);
                setOpen(false);
              }}>
                {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                <ListItemText primary={sidelnk.name} />
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />

      </Drawer>
      <BottomNavigation value={value} onChange={handleBottomChange} className={classes.stickToBottom}>
     <BottomNavigationAction showLabel={true} label="Home" value="/" icon={<HomeIcon />} />
     <BottomNavigationAction showLabel={true} label="Services" value="/servicerequest" icon={<BusinessCenterIcon />} />
     <BottomNavigationAction showLabel={true} label="Jobs" value="/jobopening" icon={<WorkIcon />} />
     <BottomNavigationAction showLabel={true} label="Results" value="/tabs/results" icon={<SchoolIcon />} />
   </BottomNavigation>
    </div>
    
  );
}
// { name: 'About Us', link: '/Aboutus' }, { name: 'Services', link: "/servicerequest" }, { name: 'Job Openings', link: '/jobopening' }, 
SiteSidebar = connect(mapStateToProps, mapDispatchToProps)(SiteSidebar);

export default SiteSidebar;