import React from "react";
import Axios from "axios";
import Constants from "../../constant";
import { Carousel } from "react-bootstrap";

class ProductCarousel extends React.Component {
    constructor(props) {
        super(props);
console.log(props);

    }
    render() {
        return (
            <div>
                <Carousel>
                    {this.props.images.map((offer,index) => (<Carousel.Item key={index}>
                        <a href={offer.link}>
                            <img
                                className="d-block w-100 h-50"
                                src={Constants.STORAGE_URL + offer.image}
                                alt="First slide"
                            />
                        </a>
                        <Carousel.Caption style={{ color: 'black' }}>
                            <h3>{offer.title}</h3>
                            <p>{offer.description}</p>
                        </Carousel.Caption>
                    </Carousel.Item>))}
                </Carousel>
            </div>
        );
    }
}
export default ProductCarousel;
