import React from "react";
import { Container, Row, Col, Card, CardBody } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Toi from "../tabs/images/TOI.png";
import DBasker from "../tabs/images/Dbaskar.jpg";
import Hindus from "../tabs/images/HT.jpg";
import TIE from "../tabs/images/TIE.jpg";
import N18p from "../tabs/images/N18P.jpg";
import Ajit from "../tabs/images/ajit.jpg";
import JagBani from "../tabs/images/jagbani.png";
import PUNT from "../tabs/images/Punjabit.jpg";
import Aajtak from "../tabs/images/Aaj_tak.png"
import Jagran from "../tabs/images/jagran.jpg"
import AmarUjala from "../tabs/images/amarujala.jpg"
import TheHindu from "../tabs/images/thehindu.png"


const newstab = () => {
  return (
    <div>
      <Container className="">
        <Row >

          <Col sm="12" className="news-heading">
            <h3>English News WebSites</h3>
          </Col>
        </Row>
        <Row>
          <Col xs='6' sm="6" md="6" lg="3">
            <a href="https://timesofindia.indiatimes.com/" target="_blank">
              <Image src={Toi} height="200" width="100%"  />
            </a>
          </Col>
          <Col xs='6' sm="6" md="6" lg="3">
            <a href="https://www.hindustantimes.com/" target="_blank">
              <Image
                src={Hindus}
                width="100%"
                height="200"
                
              />
            </a>
          </Col>
          <Col xs='6' sm="6" md="6" lg="3">
            <a href="http://epaper.indianexpress.com/" target="_blank">
              <Image src={TIE} width="100%" height="200"  />
            </a>
          </Col>
          <Col xs='6' sm="6" md="6" lg="3">
            <a href=" https://www.thehindu.com/" target="_blank">
              <Image src={TheHindu} width="100%" height="200"  />
            </a>
          </Col>
         
        </Row>
        <Row >

          <Col sm="12" className="news-heading">
            <h3>Hindi News WebSites</h3>
          </Col>
        </Row>
        <Row>
      
          <Col xs='6' sm="6" md="6" lg="3">

            <a href="https://aajtak.intoday.in/" target="_blank">
              <Image src={Aajtak} height="200" width="100%"  />
            </a>


          </Col>
          <Col xs='6' sm="6" md="6" lg="3">
            <a href="https://www.bhaskar.com/" target="_blank">
              <Image
                src={DBasker}
                width="100%"
                height="200"
                
              />
            </a>
          </Col>
     
          <Col xs='6' sm="6" md="6" lg="3">
            <a href="https://www.jagran.com/" target="_blank">
              <Image
                src={Jagran}
                width="100%"
                height="200"
                
                />
            </a>
          </Col>
          <Col xs='6' sm="6" md="6" lg="3">
            <a href=" https://www.amarujala.com/" target="_blank">
              <Image
                src={AmarUjala}
                width="100%"
                height="200"
                
              />
            </a>
          </Col>
         
        </Row>
        <Row>
          <Col sm="12" className="news-heading">
            <h3>Punjabi News WebSites</h3>
          </Col>
        </Row>
        <Row>
          <Col xs='6' sm="6" md="6" lg="3">
            <a href="https://punjab.news18.com/" target="_blank">
              <Image src={N18p} height="200" width="100%"  />
            </a>
          </Col>
          <Col xs='6' sm="6" md="6" lg="3">
            <a href="http://www.ajitjalandhar.com/" target="_blank">
              <Image
                src={Ajit}
                width="100%"
                height="200"
                
              />
            </a>
          </Col>
          <Col xs='6' sm="6" md="6" lg="3">
            <a href="https://jagbani.punjabkesari.in/" target="_blank">
              <Image
                src={JagBani}
                width="100%"
                height="200"
                
              />
            </a>
          </Col>
          <Col xs='6' sm="6" md="6" lg="3">
            <a href="https://www.punjabitribuneonline.com/" target="_blank">
              <Image src={PUNT} width="100%" height="200"  />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default newstab;
