import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import cbse from "./images/cbse.jpg";
import ncert from "./images/ncert.jpg";
import pseb from "./images/pseb.jpeg";
import icse from "./images/icse.jpeg";
import LPU from "./images/LPU.jpg";
import CU from "./images/CU.jpg";
import GNDU from "./images/GNDU.jpg";
import PUP from "./images/puchd.jpg";
import "./tabs.css";
import { Paper  } from "@material-ui/core";

const Results = () => {
  return (
    <div>
      <Container className="mt-5">
        <Row>
          <Col sm="12"  className="news-heading">
            <h3>University Results</h3>
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="6" md="6" lg="3" >
            <Paper className='mt-1'>
            <a href="https://www.lpu.in/">
              <Image src={LPU} height="200" width="100%" className="tabsimage" />
              
            </a>
            </Paper >
          </Col>
          <Col xs="6" sm="6" md="6" lg="3" >
          <Paper>
            <a href="http://www.cuchd.in/">
              <Image src={CU} height="200" width="100%" className="tabsimage" />
              
            </a>
            </Paper>
          </Col>
          <Col xs="6" sm="6" md="6" lg="3" >
          <Paper>
            <a href="http://online.gndu.ac.in/index.aspx">
              <Image src={GNDU} height="200" width="100%" className="tabsimage"/>
              
            </a>
            </Paper>
          </Col>
          <Col xs="6" sm="6" md="6" lg="3" >
          <Paper>
            <a href="http://results.puchd.ac.in/">
              <Image src={PUP} height="200" width="100%" className="tabsimage" />
              
            </a>
            </Paper>
          </Col>
          
        </Row>
        <Row>
          <Col xs="12" sm="12"  className="news-heading">
            <h3>School Results</h3>
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="6" md='6' lg='3'>
          <Paper className='mt-1'>
            <a href="http://cbse.nic.in/" >
              <Image src={cbse} height="200" width="100%" className="tabsimage"/>
              
            </a>
            </Paper >
          </Col>
          <Col xs="6" sm="6" md="6" lg="3" >
          <Paper className='mt-1'>
            <a href="http://www.ncert.nic.in/">
              <Image src={ncert} height="200" width="100%" className="tabsimage"/>
              
            </a>
            </Paper >
          </Col>
          <Col xs="6" sm="6" md="6" lg="3">
          <Paper className='mt-1'>
            <a href="https://www.pseb.ac.in/" >
              <Image src={pseb} height="200" width="100%" className="tabsimage"/>
              
            </a>
            </Paper >
          </Col>
          <Col xs="6" sm="6" md="6" lg="3" >
          <Paper className='mt-1'>
            <a href="https://www.cisce.org/" >
              <Image src={icse} height="200" width="100%" className="tabsimage" />
              
            </a>
            </Paper >
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Results;
