import React from 'react';
import { Card  } from 'react-bootstrap';
import Constants from "../../../constant"
// import Category from "./categories/Category";
// import Axios from "axios";
class Product extends React.Component {
    constructor(props) {
        super(props)
        this.state = {};
        this.render=this.render.bind(this);
    }


    render() {
        return (
            <div>
                <a href="" >
                    <Card className="mt-3">
                        <Card.Img variant="top" src={Constants.STORAGE_URL + this.props.data.image} />
                        <Card.Body>
                            <Card.Title>{this.props.data.name}</Card.Title>
                        </Card.Body>
                    </Card>
                </a>
            </div>
        );
    }
}
export default Product;
