import React from 'react';
import { connect } from "react-redux";
import { Redirect  } from "react-router-dom";

const mapStateToProps = state => {
    console.log(state);
    return { logged_in: state.logged_in };
};

var IsNotLoggedIn = (props) => {
    console.log(props)
    
    if(!props.logged_in){
        return <div>{props.children}</div>
    }else if(props.logged_in){
         return <Redirect to='/' />
    }
}
 IsNotLoggedIn = connect(mapStateToProps)(IsNotLoggedIn);

export default IsNotLoggedIn;