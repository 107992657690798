import React from "react";
import { Card } from "react-bootstrap";
import Constants from "../../../constant";
import {Link} from "react-router-dom";


const Category = props => {
  console.log(props.data);
  

  return (
   
    <div>
      
      <Link to={"/businessdirectory/" + props.data.id}>
        <Card 
          className="mt-3"
          style={{borderRadius:'100%'}}
        >
          <Card.Img
            variant="top"
            src={Constants.STORAGE_URL + props.data.image}
            style={{ height:"65px" ,width: "100%", borderRadius:'100%' }}
          />
         
        </Card>
       <span className="label_text">{props.data.name}</span> 
        </Link>
    </div>
  );
};
export default Category;
