import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Card,
  Form,
  Alert,
} from "react-bootstrap";
import Axios from "axios";
import Constants from "../../../constant";
import { Snackbar, Paper } from "@material-ui/core";
import { trackPromise } from "react-promise-tracker";
import { Redirect } from "react-router-dom";

class ServiceRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CardBorder: " ",
      data: [],
      empty: false,
      uname: "",
      umail: "",
      unumber: "",
      uaddress: "",
      AlertType: "",
      service: "",
      success: false,
      message: [],
    };
    trackPromise( Axios.get(Constants.BASE_URL_API + "getservices").then((res) => {
      console.log(res.data);
      if (res.data === "No Service Found") {
        this.setState({ error: true });
      } else {
        this.setState({ data: res.data });
      }
    }));
  }
  dataChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  Submithandler = (e) => {
    e.preventDefault();
    var data = {
      name: this.state.uname,
      mail: this.state.umail,
      number: this.state.unumber,
      service: this.state.service,
      address: this.state.uaddress,
    };
    trackPromise( Axios.post(Constants.BASE_URL_API + "ServiceRequest", data).then((res) => {
      console.log(res.data.STATUS);
      if (res.data === "Success") {
        this.setState({
          CardBorder: "success",
          success: true,
          message: ["Your Request Has Been Submitted."],
          AlertType: "success",
        });
        var that=this;
        setTimeout(function () {
            that.setState({ goto: "/" });
        }, 4000);
      } else if (res.data.STATUS === "FAIL") {
        var that=this;
        
        setTimeout(function () {
          that.setState({
            CardBorder: "danger",
            success: false,
            message: null,
            AlertType: "error",
          });
        }, 4000);
        this.setState({
          CardBorder: "danger",
          success: true,
          message: res.data.ERROR.MESSAGE,
          AlertType: "error",
        });
      }
    }));
  };
  render() {
    const { uname, umail, unumber, service, uaddress } = this.state;
    return (
      <div>
        {this.state.goto ? (
          <Redirect to={this.state.goto}></Redirect>
        ) : (
          <div></div>
        )}

        <Container className="mt-3 ">
          {this.state.error ? (
            <Row className="text-center">
              <Col sm="12">
                <h1 id="heading">Sorry Services Upcoming Soon!</h1>
              </Col>
            </Row>
          ) : (
            <div>
              <Row>
                <Col sm="12">
                  <h1>
                    <span id="datahead">Service</span> Request
                  </h1>
                </Col>
              </Row>
              <Snackbar
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={this.state.success ? true : false}
                message={this.state.message}
                onClose={() => {
                  this.setState({ error: false });
                }}
              ></Snackbar>
              <Row>
                <Col sm="12" className="text-left">
                  <Paper elevation={3}>
                    <Card border={this.state.CardBorder}>
                      <Card.Body>
                        <Form onSubmit={this.Submithandler}>
                          <Row id="formrow">
                            <Col sm="12">
                              <Form.Label>
                                <div className="vr" />
                                Select Service
                              </Form.Label>
                              <Form.Control
                                as="select"
                                defaultValue={"DEFAULT"}
                                name="service"
                                onChange={this.dataChange}
                              >
                                <option disabled value="DEFAULT">
                                  --Choose Service--
                                </option>

                                {this.state.data.map((list) => (
                                  <option key={list.id} value={list.id}>
                                    {list.name}
                                  </option>
                                ))}
                              </Form.Control>
                            </Col>
                          </Row>
                          <Row id="formrow">
                            <Col sm={12}>
                              <Form.Label>
                                <div className="vr" />
                                Name
                              </Form.Label>
                              <Form.Control
                                className="input"
                                type="text"
                                value={this.state.uname}
                                onChange={this.dataChange}
                                name="uname"
                                placeholder="Enter Name"
                              />
                            </Col>
                          </Row>
                          <Row id="formrow">
                            <Col sm={12}>
                              <Form.Label>
                                <div className="vr" />
                                Email
                              </Form.Label>
                              <Form.Control
                                className="input"
                                type="text"
                                value={this.state.umail}
                                onChange={this.dataChange}
                                name="umail"
                                placeholder="Enter Email"
                              />
                            </Col>
                          </Row>
                          <Row id="formrow">
                            <Col sm={12}>
                              <Form.Label>
                                <div className="vr" />
                                Phone Number
                              </Form.Label>
                              <Form.Control
                                className="input"
                                type="text"
                                value={this.state.unumber}
                                onChange={this.dataChange}
                                name="unumber"
                                placeholder="Enter Number"
                              />
                            </Col>
                          </Row>
                          <Row id="formrow">
                            <Col sm={12}>
                              <Form.Label>
                                <div className="vr" />
                                Address
                              </Form.Label>
                              <Form.Control
                                className="input"
                                as="textarea"
                                rows={3}
                                value={this.state.uaddress}
                                onChange={this.dataChange}
                                name="uaddress"
                                placeholder="Enter Address"
                              />
                            </Col>
                          </Row>
                          <Row id="formrow">
                            <Col sm={12}>
                              <Button variant="success" type="submit" block>
                                Submit
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Paper>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    );
  }
}
export default ServiceRequest;
