import React from "react";
import Axios from "axios";
import Constants from "../../../constant";
import { Carousel,Image} from "react-bootstrap";

class Carouseltwo extends React.Component {
  constructor(props) {
    super(props);

    this.state = { offers: [] };
    Axios.get(Constants.BASE_URL + "api/Adstwo").then(res => {
      this.setState({ offers: res.data });
    });
  }
  render() {
    return (
      <div>
        <Carousel>
            {this.state.offers.map(offer => (<Carousel.Item>
            
            <Image
              style={{ width:"100%",
                height:"180px"}}
              src={Constants.STORAGE_URL+offer.image}
              alt="First slide"
             
            />
           
          </Carousel.Item>))}
        </Carousel>
      </div>
    );
  }
}
export default Carouseltwo;
