import React from "react";
import { Container, Row, Col, Form, Button, Card, Alert } from "react-bootstrap";
import Constants from "../../constant";
import Axios from "axios";
import { connect } from "react-redux";
import { loginUser } from "../../actions/actions";
import { Paper, Typography, Snackbar } from "@material-ui/core";
import { trackPromise } from "react-promise-tracker";



const mapStateToProps = state => {
    return { user: state.user };
};

function mapDispatchToProps(dispatch) {
    return {
        loginUser: userdata => dispatch(loginUser(userdata))
    };
}

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            error: ""
        }
        // this.login = this.login.bind(this);
        this.emailRef = React.createRef();
        this.passwordRef = React.createRef();
        this.nameRef = React.createRef();
        this.mobileRef = React.createRef();
        this.addressRef = React.createRef();
        this.confirmPasswordRef = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.confirmPassword = this.confirmPassword.bind(this);
    }

    confirmPassword() {
        if (this.passwordRef.current.value !== this.confirmPasswordRef.current.value) {
            this.confirmPasswordRef.current.setCustomValidity("Passwords do not match!");
        } else {
            this.confirmPasswordRef.current.setCustomValidity('')
        }
    }

    handleSubmit(event) {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (this.passwordRef.current.value !== this.confirmPasswordRef.current.value) {
            this.confirmPasswordRef.current.setCustomValidity("Passwords do not match!");
        } else {
            this.confirmPasswordRef.current.setCustomValidity('')
        }
        if (form.checkValidity() === false) {

        } else {
            let name = this.nameRef.current.value;
            let email = this.emailRef.current.value;
            let mobile = this.mobileRef.current.value;
            let address = this.addressRef.current.value;
            let password = this.passwordRef.current.value;
            let password_confirmation = this.confirmPasswordRef.current.value;
            trackPromise( Axios.post(Constants.BASE_URL_API + 'register', { name, email, password, password_confirmation, mobile, address }).then((res) => {
                if (res.data.STATUS === "SUCCESS") {
                    this.props.loginUser(res.data.DATA);
                } else {
                    this.setState({ error: res.data.ERROR.MESSAGE[0] });
                    setTimeout(() => {
                        this.setState({ error: "" });
                    }, 6000);
                }
            }).catch((e, re) => {
                if (e.response.data) {
                    if (e.response.data.ERROR) {
                        this.setState({ error: e.response.data.ERROR.MESSAGE[0] });
                        setTimeout(() => {
                            this.setState({ error: "" });
                        }, 6000);
                    }
                } else {
                    this.setState({ error: "Something went wrong." });
                    setTimeout(() => {
                        this.setState({ error: "" });
                    }, 6000);
                }


            }))
        }
        this.setState({ validated: true });

        // return false;



    };
    componentDidMount() {
    }
    render() {


        const longEnough = (val) => val && val.length > 8;


        return (
            <div>


                <Container className="my-5">
                    <Row className="my-3 text-left">
                        <Col sm="12" md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
                            <Snackbar
                            autoHideDuration={6000}
                            severity="error"
                            anchorOrigin={{vertical:'top',horizontal:'center'}}
                                open={this.state.error?true:false}
                                message={this.state.error}
                                onClose={()=>{
                                    this.setState({error:false});
                                }}
                            />
                           
                            <Paper className='p-3 ' elevation={4} style={{backgroundColor:"#f5f5f5"}}>
                                <Typography variant='h3' align="center" style={{fontVariant:"inherit"}} className="mb-3">Signup</Typography>

                                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit} validators={{
                                    '': {
                                        // Form-level validator
                                        passwordsMatch: (vals) => vals.password === vals.confirmPassword,
                                    },
                                    // Field-level validators
                                    password: { longEnough },
                                    confirmPassword: { longEnough }
                                }}>
                                    <Form.Group controlId="formBasicName">
                                        <Form.Label style={{fontWeight:"bold"}}>Full Name<span className="filed-Star">*</span></Form.Label>
                                        <Form.Control required name="name" minLength="3" type="text" placeholder="Enter Full Name" ref={this.nameRef} />

                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid name.
                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label style={{fontWeight:"bold"}}>Mobile Number<span className="filed-Star">*</span></Form.Label>
                                        <Form.Control name="mobile" required type="text" placeholder="Enter Mobile Number" ref={this.mobileRef} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid mobile number.
                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label style={{fontWeight:"bold"}}>Email Address</Form.Label>
                                        <Form.Control name="email" type="email" placeholder="Enter Email Address" ref={this.emailRef} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid email address.
                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label style={{fontWeight:"bold"}}> Address</Form.Label>
                                        <textarea className="form-control" name="address" type="email" placeholder="Enter Address" ref={this.addressRef} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid  address.
                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label style={{fontWeight:"bold"}}>Password<span className="filed-Star">*</span></Form.Label>
                                        <Form.Control name="password" minLength="6" required type="password" placeholder="Enter Password" ref={this.passwordRef} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a password. password must be atleast 6 character long
                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicConfirmPassword">
                                        <Form.Label style={{fontWeight:"bold"}}>Confirm Password<span className="filed-Star">*</span></Form.Label>
                                        <Form.Control name="confirmPassword" minLength="6" onChange={this.confirmPassword} required type="password" placeholder="Confirm Password" ref={this.confirmPasswordRef} />
                                        <Form.Control.Feedback type="invalid">
                                            Please confirm the password. password must be atleast 6 character long
                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button variant="success" type="submit"  >
                                        Submit
                    </Button>
                                </Form>
                            </Paper>
                        </Col>
                    </Row>
                </Container>
            </div >
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);


