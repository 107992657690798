import React from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';


import { usePromiseTracker } from "react-promise-tracker";

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));
const Loader = props => {
    const { promiseInProgress } = usePromiseTracker();
    const classes = useStyles();
    return  <Backdrop className={classes.backdrop} open={promiseInProgress} >
    <CircularProgress color="inherit" />
  </Backdrop>
};
export default Loader;