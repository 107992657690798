import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Axios from "axios";
import { getCurrentDate } from "../../../node_modules/util";
import Constants from "../../constant";
import { trackPromise } from "react-promise-tracker";

class currentaffairs extends React.Component {
  constructor(props) {
    super(props);

    this.state = { offers: [], page: 1, lastPage: 1 };
    trackPromise(  Axios.get(Constants.BASE_URL + "api/currentaffair").then(res => {
      this.setState({ offers: res.data.data, lastPage: res.data.last_page });
    }));
    this.getMoreAffair = this.getMoreAffair.bind(this);
    this.moreAffair = this.moreAffair.bind(this);
  }
  getMoreAffair(page) {
    page++;
    console.log(page);
    this.setState({ loadingMore: true, page: page });
    trackPromise( Axios.get(Constants.BASE_URL + "api/currentaffair?page=" + page).then(
      res => {
        var offers = this.state.offers;
        if (offers.length > 0) {
          offers = offers.concat(res.data.data);
        } else {
          offers = res.data.data;
        }
        this.setState({ offers: offers, loadingMore: false });
      }
    ));
  }
  moreAffair() {
    this.getMoreAffair(this.state.page);
  }
  render() {
    return (
      <div>
        <Container className="">
          
          <Row>
            <Col sm="12">
             
              <h3 className="white-black">Current Affairs</h3>
              
            </Col>
          </Row>
          <Row>
            {this.state.offers.map(offer => (
              <Col sm={12} md={6} lg={4}>
                <a href={Constants.STORAGE_URL + offer.image}>
                  <Card
                    border="dark"
                    className="mt-3"
                    style={{ width: "18rem" }}
                  >
                    <Card.Img
                      variant="top"
                      src={Constants.STORAGE_URL + offer.image}
                    />
                    {/* <Card.Body style={{ backgroundColor: "#cfd8dc" }}>
                      <Card.Title></Card.Title>
                    </Card.Body> */}
                  </Card>
                </a>
              </Col>
            ))}
          </Row>
          {this.state.page == this.state.lastPage ? (
            <div></div>
          ) : (
            <Row className="my-3 text-center">
              {!this.state.loadingMore ? (
                <Button
                  size="lg"
                  variant="light"
                  block
                  onClick={this.moreAffair}
                >
                  Older post
                </Button>
              ) : (
                <div className="text-center w-100">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </Row>
          )}
          
        </Container>
      </div>
    );
  }
}
export default currentaffairs;
